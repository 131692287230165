import { showToastAction } from "store/slices/commonSlice";
import api from "../../services/api";
import {
  authenticate,
  getUserInfo,
  isAuthenticated,
  logout,
  storeUserInfo,
} from "../../services/auth";
import endPoints from "../../services/endPoints";
import { setUserProfile } from "../slices/userSlice";
import { handleFileConversion } from "utils/commonFunctions";

const onUserLoginAction =
  (data, loading, navigate, onError) => async (dispatch) => {
    let payload = { email: data.email, password: data.loginPassword };
    loading(true);
    const res = await api("post", endPoints.LOGIN, payload);
    if (res.success) {
      let user = res?.data?.results?.user;
      user.rememberMe = data.rememberMe
      let token = res.data.results?.access_token;
      storeUserInfo(user, data.rememberMe);
      authenticate(token, data.rememberMe, () => {
        dispatch(setUserProfile(user));
        if (isAuthenticated()) {
          navigate("/dashboard");
        } else {
          navigate("/");
        }
      });
    } else {
      onError(res.message);
    }
    loading(false);
  };

const onUserSignUpAction = (data, loading, navigate, onRes) => async () => {
  let payload = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    password: data?.password,
    confirm_password: data?.confirmPassword,
    email: data?.businessEmail,
  };
  loading(true);
  const res = await api("post", endPoints.REGISTER, payload);
  onRes(res);
  loading(false);
};

const verifyTokenAction = (token, dispatch, navigate, onRes) => async () => {
  const res = await api("get", endPoints.VERIFY_TOKEN + `?${token}`);
  if (res?.success) {
    onRes(res?.data?.results?.email)
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
    navigate("/")
  }
};

const setupAccountAction = (data, token, loading, navigate, dispatch) => async () => {
  let payload = {
    // first_name: data?.firstName,
    // last_name: data?.lastName,
    password: data?.password,
    confirm_password: data?.confirmPassword,
    // phone_number: data?.phoneNumber,
  };
  loading(true);
  const res = await api("post", endPoints.SETUP_ACCOUNT + `?${token}`, payload);
  if (res?.success) {
    dispatch(showToastAction({
      type: "success",
      detail: res?.message,
    }))
    navigate("/")
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }
  loading(false);
};

const onOtpVerification = (data, loading, onRes) => async () => {
  let payload = {
    email: data?.email,
    otp: data?.otp,
  };

  loading(true);
  const res = await api(
    "post",
    endPoints?.VERIFY_OTP + `${data?.action}/`,
    payload
  );
  onRes(res);
  loading(false);
};

const onSendOtpAction = (payload, loading, dispatch, onRes) => async () => {
  loading && loading(true);
  const res = await api(
    "post",
    endPoints.SEND_OTP + `${payload?.action}/`,
    payload
  );

  if (res?.success) {
    dispatch(showToastAction({
      type: "success",
      detail: res?.message,
    }))
    onRes(res);

  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }
  loading && loading(false);

};

const onCreateNewPasswordAction = (data, loading, dispatch, onRes) => async () => {
  loading(true);

  let payload = {
    email: data?.email,
    password: data?.password,
    confirm_password: data?.confirmPassword,
  };

  const res = await api("post", endPoints?.CHANGE_FORGOT_PASSWORD, payload);
  if (res?.success) {
    onRes(res);
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }

  loading(false);
};

const getProfileAction = (dispatch, onRes) => async () => {
  const res = await api("get", endPoints.PROFILE);
  if (res.success) {
    const info = res?.data?.results
    if (info?.is_blocked) {
      logout()
      window.location.href = "/";
    } else {
      const userData = getUserInfo()
      let data = {
        ...info,
        rememberMe: userData?.rememberMe
      }
      storeUserInfo(data, userData?.rememberMe);
      dispatch(setUserProfile(data));
      onRes && onRes(data)
    }
  }
};

const deleteAccountAction = (setLoading, dispatch, navigate) => async () => {
  setLoading(true)
  const res = await api("delete", endPoints.DELETE_USER);
  if (res.success) {

    dispatch(showToastAction({
      type: "success",
      detail: res?.message,
    }))
    logout()
    navigate("/")
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }
  setLoading(false)

};

const changePasswordAction = (data, setLoading, dispatch, onRes) => async () => {
  setLoading(true)
  const payload = {
    old_password: data?.oldPassword,
    new_password: data?.newPassword,
    confirm_password: data?.confirmPassword,
  }

  const res = await api("post", endPoints.CHANGE_PASSWORD, payload);
  if (res.success) {
    dispatch(showToastAction({
      type: "success",
      detail: res?.message,
    }))
    onRes(res)
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }
  setLoading(false)
};

const updateProfileAction = (data, setLoading, dispatch, navigate, onRes) => async () => {
  setLoading(true)
  const payload = {
    first_name: data?.firstName,
    last_name: data?.lastName,
  }

  if (data.file) {
    const profilePic = await handleFileConversion(data.file)
    payload.profile_photo = profilePic
  }

  const res = await api("post", endPoints.UPDATE_USER, payload);
  if (res.success) {
    dispatch(showToastAction({
      type: "success",
      detail: res?.message,
    }))
    navigate("/settings/profile")
    dispatch(getProfileAction(dispatch))
    onRes && onRes(res)
  } else {
    dispatch(showToastAction({
      type: "error",
      detail: res?.message,
    }))
  }
  setLoading(false)
}



export {
  onUserLoginAction,
  onUserSignUpAction,
  onOtpVerification,
  onSendOtpAction,
  onCreateNewPasswordAction,
  getProfileAction,
  setupAccountAction,
  verifyTokenAction,
  deleteAccountAction,
  changePasswordAction,
  updateProfileAction
};
