import React, { useEffect } from "react";
import ExternalRentTableForm from "../ExternalRentTableForm";
import OverRideControlForm from "../OverRideControlForm";
import formValidation from "utils/validations";
const Tier4 = ({ data, tierData, setTierData }) => {

    useEffect(() => {
        if (data) {
            setTierData((prev) => ({ ...prev, data }))
        }

        setTierData({
            data: data?.data || data,
            id: tierData?.id || data?.id || null,
            deductionControl: tierData?.deductionControl || data?.deduction_control || null,
            overRideControl: tierData?.overRideControl == undefined ? data?.override_control : tierData?.overRideControl ? "Active" : "Inactive",
            areaSelection: tierData?.areaSelection || data?.area_selection || "abstractArea",
        })
    }, [data])

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, tierData)
        setTierData((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    return (
        <>
            <ExternalRentTableForm data={data?.data || data} tierData={tierData} handleChange={handleChange} />
            <OverRideControlForm handleChange={handleChange} data={tierData} />
        </>
    )
}

export default Tier4