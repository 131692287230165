import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomPassword } from "shared/AllInputs";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";
import { changePasswordAction } from "store/actions/userActions";
import { showFormErrors } from "utils/commonFunctions";
import { equal } from "utils/javascript";
import formValidation from "utils/validations";

const ChangePassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [loading, setLoading] = useState()

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data)
        setData((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    useEffect(() => {
        if (data?.newPassword && data?.confirmPassword) {
            if (equal(data?.newPassword, data?.confirmPassword)) {
                const formErrors = formValidation("confirmPassword", data?.confirmPassword, data);
                setData((prev) => ({ ...prev, ["confirmPassword"]: data?.confirmPassword, formErrors }));
            } else {
                const formErrors = formValidation("confirmPassword", data?.confirmPassword, data);
                setData((prev) => ({ ...prev, ["confirmPassword"]: data?.confirmPassword, formErrors }));
            }
        }
    }, [data?.newPassword]);

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(changePasswordAction(data, setLoading, dispatch, (res) => {
                setData({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                })
                navigate("/settings/profile")
            }))
        }
    }

    return (
        <div className="flex justify-content-center align-items-center h-30rem ">
            <div className="lg:w-6 bg-white p-4 border-round-lg shadow-1">
                <div className={`flex gap-2 justify-content-between flex-wrap`}>
                    <div className="title my-auto">Change Password</div>
                </div>
                <CustomPassword name="oldPassword" data={data} ignoreLabel col={12} onChange={handleChange} />
                <CustomPassword name="newPassword" data={data} ignoreLabel col={12} onChange={handleChange} />
                <CustomPassword name="confirmPassword" data={data} ignoreLabel col={12} onChange={handleChange} />
                <Buttonlayout className="flex-column">
                    <CustomButton label="Save Changes" extraClassNames={'w-full my-3'} onClick={onSubmit} loading={loading} />
                    <CustomButtonOutlined label="Cancel" extraClassNames={'w-full'} onClick={() => navigate("/settings/profile")} />
                </Buttonlayout>
            </div>
        </div>
    )
}

export default ChangePassword