import React, { useMemo } from "react";
import CustomAddTable from "views/Tables/CustomAddTable";
// import { HandleAddNew } from "./commonFunctions";
import CustomButton from "shared/CustomButton";
import { CustomDropDown, CustomInput } from "shared/AllInputs";

const AddCoTenant = ({ allValues, setAllValues }) => {

    const header = (
        <div className="w-12 flex justify-content-end flex-wrap">
            <CustomButton
                type="button"
                label="Add"
            // onClick={() => HandleAddNew(roomInputObj, allValues, setAllValues)}
            />
        </div>
    );

    const columns = useMemo(() => [
        {
            name: "First Name",
            assessor: "",
            editor: () => <CustomInput name="firstName" data="" onChange={() => { }} col={12} ignoreLabel />
        },
        {
            name: "Last Name",
            assessor: "",
            editor: () => <CustomInput name="lastName" data="" onChange={() => { }} col={12} ignoreLabel />
        },
        {
            name: "Client Type",
            assessor: "",
            editor: () => <CustomDropDown options={[]} name="clientType" data="" onChange={() => { }} col={12} ignoreLabel />
        },
        {
            name: "Date of Birth",
            assessor: "",
            editor: () => <CustomInput name="dateOfBirth" data="" onChange={() => { }} col={12} ignoreLabel />
        },
        {
            name: "Email",
            assessor: "",
            editor: () => <CustomInput name="email" data="" onChange={() => { }} col={12} ignoreLabel />
        },
        {
            name: "Phone",
            assessor: "",
            editor: () => <CustomInput name="phone" data="" onChange={() => { }} col={12} ignoreLabel />
        },
    ], [])

    return (
        <CustomAddTable title={"Co-Tenant"} header={header} columns={columns} />
    )
}

export default AddCoTenant