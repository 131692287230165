import React, { useMemo } from "react";
import { CustomAmountViewInput, CustomViewInput } from "shared/AllViewInputs";
import CommonViewTable from "views/Tables/CommonViewTable";
import { getTenantName } from "../externalRentFunctions";
export default function HeatingDeductionTable({ items }) {

    const columns = useMemo(
        () => [
            {
                name: "No. of Tenants",
                accessor: "number_of_tenant",
                value: true,
                body: (options) => <CustomViewInput value={getTenantName(options.number_of_tenant)} />
            },
            {
                name: "Centralized Deduction",
                accessor: "centralized_deduction",
                value: true,
                body: (options) => <CustomAmountViewInput value={options?.centralized_deduction} />
            },
        ],
        []
    );

    return (
        <CommonViewTable
            title={"Centralized Heating Deduction"}
            list={items}
            columns={columns}
        />
    );
}
