import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CustomForm, CustomInput, CustomPassword } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { isAuthenticated } from "services/auth";
import Layout from "./Layout";
import { Link, useNavigate } from "react-router-dom";
import OTPVerification from "./OtpVerification";
import {
  onCreateNewPasswordAction,
  onSendOtpAction,
} from "store/actions/userActions";
import Lottie from "lottie-react";
import completeAnimation from "assets/lottie/complete.json";
import { equal } from "utils/javascript";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("EMAIL");
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  useEffect(() => {
    if(data?.password && data?.confirmPassword){
      if (equal(data?.password, data?.confirmPassword)) {
        const formErrors = formValidation("confirmPassword", data?.confirmPassword, data);
        setData((prev) => ({...prev, ["confirmPassword"]: data?.confirmPassword, formErrors }));
      } else {
        const formErrors = formValidation("confirmPassword", data?.confirmPassword, data);
        setData((prev) => ({...prev, ["confirmPassword"]: data?.confirmPassword, formErrors }));
      }
    }
  }, [data?.password]);

  const ignore = ["password", "confirmPassword"];

  const emailTemplate = () => {
    return (
      <Layout title="Forgot Password?">
        <CustomForm className="form">
          <CustomInput
            data={data}
            name="email"
            label="Email Address"
            required
            col="12"
            onChange={handleChange}
          />
          <CustomButton
            onClick={onSendOTP}
            loading={loading}
            extraClassNames="w-full my-4"
            label="Send OTP"
          />
          <br />
          <br />
          <div className="w-full text-center">
            <Link className="link" to="/">
              Back to login
            </Link>
          </div>
        </CustomForm>
      </Layout>
    );
  };
  const onSendOTP = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData, ignore)) {
      let payload = {
        action: "forgot",
        email: data?.email,
      };
      dispatch(
        onSendOtpAction(payload, setLoading, dispatch, (res) => {
          setStatus("OTP");
        })
      );
    }
  };

  const passwordTemplate = () => {
    return (
      <Layout title="Create New Password">
        <CustomForm>
          <CustomPassword
            data={data}
            name="password"
            label="New Password"
            onChange={handleChange}
            col={12}
          />
          <CustomPassword
            data={data}
            name="confirmPassword"
            onChange={handleChange}
            col={12}
          />
          <CustomButton
            loading={loading}
            onClick={onCreateNewPassword}
            extraClassNames="w-full my-4"
            label="Continue"
          />
        </CustomForm>
      </Layout>
    );
  };
  const onCreateNewPassword = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      dispatch(
        onCreateNewPasswordAction(data, setLoading, dispatch, (res) => {
          setStatus("COMPLETED");
        })
      );
    }
  };
  const completeTemplate = () => {
    return (
      <Layout title="Password Reset Completed">
        <p className="text-dark-color">
          Now, you can log in to your account with the new password.
        </p>
        <br />

        <Lottie
          animationData={completeAnimation}
          loop={false}
          className="mx-auto"
          style={{ width: "400px" }}
        />

        <CustomButton
          extraClassNames="w-full my-4"
          onClick={() => navigate("/")}
          label="Got It"
        />
      </Layout>
    );
  };

  return (
    <>
      {status === "EMAIL" && emailTemplate()}
      {status === "OTP" && (
        <OTPVerification
          action={"forgot"}
          email={data.email}
          setOtpVerified={(e) => e && setStatus("PASSWORD")}
        />
      )}
      {status === "PASSWORD" && passwordTemplate()}
      {status === "COMPLETED" && completeTemplate()}
    </>
  );
}
