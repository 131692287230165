import React, { useEffect, useRef, useState } from "react";
import { CustomAutoComplete, CustomDropDown, CustomForm } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { PositionOptions } from "../PositionsList";
import { searchEmployeesAction } from "store/actions/EmployeeActions";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import CustomOverlay from "shared/CustomOverlay";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import { deleteOfficeEmployeeAction, getOfficeEmpDetailsAction } from "store/actions/officeActions";
import CustomModal from "shared/Modals/CustomModal";
import { debounce } from "lodash";
const AddExistingOfficeEmployee = ({ allEmployees, setAllEmployees }) => {
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const ignoreInputs = ["status", "id"];
  const InputObj = {
    id: "",
    position: "",
    employee: "",
    status: "",
  };
  const [employeesList, setEmployeesList] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile?.company) {
      getEmployeesList(profile?.company);
    }
  }, [profile]);

  const getEmployeesList = (company, value, index, allValues) => {
    dispatch(
      searchEmployeesAction(company, value, "", setEmployeesList, (error) => {
        const values = [...allValues]
        let errors = values[index]?.formErrors || {}
        let empError = error
        let formErrors = { ...errors, "employee": empError }
        let myObj = {
          ...values[index],
          employee: value,
          formErrors: formErrors
        }
        values[index] = myObj
        setAllEmployees(values)
      })
    );
  };

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() => HandleAddNew(InputObj, allEmployees, setAllEmployees)}
      />
    </div>
  );

  const actions = (item, index) => {
    return (
      <>
        {item.status === "save" ? (
          <CustomOverlay ref={openAction}>
            <ul className="no-style p-0">
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => handlEdit(allEmployees, setAllEmployees, index)}
              >
                <i className="pi pi-file-edit text-dark"></i>
                Edit
              </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setIdToDelete({ id: item?.id, index: index });
                  setDeleteModal(true);
                }}
              >
                <i className="pi pi-trash cursor-pointer text-dark"></i>
                Delete
              </li>
            </ul>
          </CustomOverlay>
        ) : (
          <div className="d-flex gap-3">
            <a
              className="cursor-pointer"
              onClick={() => {
                if (allEmployees[index]?.employee?.id) {
                  handleSaveNew(index, allEmployees, setAllEmployees, ignoreInputs)
                }
                const options = allEmployees
                  ?.map((employee) => {
                    return employeesList?.filter(
                      (opt) => opt?.value !== employee?.employee
                    );
                  })
                  .flat();
                setEmpOptions(options);
              }}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={() => {
                if (item.id) {
                  getItemDetails(item.id, index);
                } else {
                  handleDeleteNew(allEmployees, setAllEmployees, index);
                }
              }}
            >
              <img src={CrossIcon} />
            </a>
          </div>
        )}
      </>
    );
  };

  const getItemDetails = (id, index) => {
    dispatch(
      getOfficeEmpDetailsAction(id, (res) => {
        let data = {
          id: res?.id,
          position: res?.position,
          employee: res?.employee?.id
        }
        handleCancelNew(data, index, allEmployees, setAllEmployees);
      })
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office employee from the
        list? This action cannot be undone.
      </span>
    );
  };

  const handleDeleteEmp = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteOfficeEmployeeAction(
          idToDelete?.id,
          setLoading,
          dispatch,
          (res) => {
            handleDeleteNew(allEmployees, setAllEmployees, idToDelete?.index);
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          }
        )
      );
    } else {
      handleDeleteNew(allEmployees, setAllEmployees, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setEmpOptions(employeesList)
  }, [])

  useEffect(() => {
    const options = employeesList?.map((option) => {
      const isDisabled = allEmployees?.some(employee => employee?.employee?.id === option.value);
      return {
        ...option,
        disabled: isDisabled,
      };
    });

    setEmpOptions(options);
  }, [allEmployees, employeesList]);

  const employeeChange = useRef(debounce((company, value, index, allValues) => {
    getEmployeesList(company, value, index, allValues)
  }, 1000));

  return (
    <>
      <CustomForm title={"Office Employees"} header={header}>
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th style={{ width: "45%" }}>Select Position</th>
                <th style={{ width: "45%" }}>Select Employee</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form">
              {allEmployees?.map((emp, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          data={emp}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEmployees,
                              setAllEmployees,
                              ignoreInputs
                            )
                          }
                          name="position"
                          col={12}
                          options={PositionOptions}
                          disabled={emp?.status === "save" ? true : false}
                          placeholder="Select Position"
                          ignoreLabel
                        />
                      </td>
                      <td>
                        {/* <CustomDropDown
                          data={emp}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEmployees,
                              setAllEmployees,
                              ignoreInputs
                            )
                          }
                          name="employee"
                          col={12}
                          options={empOptions}
                          disabled={emp?.status === "save" ? true : false}
                          placeholder="Select Employee"
                          ignoreLabel
                        /> */}
                        <CustomAutoComplete
                          field={"name"}
                          data={emp}
                          col={12}
                          onChange={(e) => {
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEmployees,
                              setAllEmployees,
                              ignoreInputs
                            )
                          }}
                          name="employee"
                          placeholder={"Search Employee"}
                          suggestions={empOptions}
                          required
                          search={(e) => employeeChange.current(profile?.company, e.query, index, allEmployees)}
                          disabled={emp?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>{actions(emp, index)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteEmp}
        loading={loading}
      />
    </>
  );
};

export default AddExistingOfficeEmployee;
