import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { createExternalRentSetupAction, filterExternalRentAction, getExternalRentSetupAction, getLinkedServicesListAction, updateTierAction } from "store/actions/RentsetupActions";
import { showFormErrors } from "utils/commonFunctions";
import formValidation from "utils/validations";
import { capitalFirstLetter } from "utils/regex";

const ExternalRentSettingsContainer = () => {
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0)
    const [regulationOptions, setRegulationOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [servicesList, setServicesList] = useState([])
    const { profile } = useSelector((state) => state.user)
    const [tiersArray, setTiersArray] = useState([])
    const [disableTabs, setDisableTabs] = useState([1, 2, 3])
    const [data, setData] = useState({
        id: null,
        regulation: "",
    })

    const tier1Initial = {
        id: null,
        deductionControl: "",
        baseRent: "",
        maintenance: "",
        overRideControl: "Active",
        areaSelection: "abstractArea",
    }

    const [tierData1, setTierData1] = useState(tier1Initial)

    const tierInitial = {
        id: null,
        deductionControl: "",
        overRideControl: "Active",
        areaSelection: "abstractArea",
    }
    const [tierData2, setTierData2] = useState(tierInitial)
    const [tierData3, setTierData3] = useState(tierInitial)
    const [tierData4, setTierData4] = useState(tierInitial)
    const [allTiers, setAllTiers] = useState([])

    useEffect(() => {
        if (data?.tiers) {
            const tiersArray = Object.values(data?.tiers || {});
            setTiersArray(tiersArray)
        }
    }, [data?.tiers])

    useEffect(() => {
        if (profile?.company) {
            getServicesList()
            getExternalRentSetup()
        }
    }, [profile?.company])

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data)

        setData((prev) => ({
            ...prev,
            id: null,
            [name]: value,
            formErrors
        }))

        if (value?.regulation_name) {
            setData((prev) => ({
                ...prev,
                id: null,
                [name]: value,
                tiers: value.tiers,
                formErrors
            }))
            setActiveIndex(0)
            setTierData1(tier1Initial)
            setTierData2(tierInitial)
            setTierData3(tierInitial)
            setTierData4(tierInitial)
            setDisableTabs([1, 2, 3])
        }
    }

    const handleSearchRegulation = (value) => {
        dispatch(filterExternalRentAction(value, dispatch, setRegulationOptions, (error) => {
            setData((prev) => ({ ...prev, formErrors: { "regulation": error } }))
        }))
    };

    const regulationChange = useRef(debounce((value) => {
        setData((prev) => ({ ...prev }));
        handleSearchRegulation(value)
    }, 1000));

    const getExternalRentSetup = () => {
        dispatch(getExternalRentSetupAction(profile?.company, dispatch, (res) => {
            if (res?.id) {
                setData({
                    id: res?.id,
                    regulation: `${res?.regulation_name} ${capitalFirstLetter(res?.federal_state)}`,
                    tiers: res?.tiers
                })
                setDisableTabs([])
            } else {
                setData({
                    id: null,
                    regulation: "",
                })
            }
            setTiersArray([])
            setActiveIndex(0)
            setAllTiers([])
        }))
    }

    const getServicesList = () => {
        dispatch(getLinkedServicesListAction(profile?.company, (res) => {
            const options = res?.options?.map((item) => {
                return {
                    ...item,
                    maximumOverride: "",
                }
            })
            setServicesList(options)
        }))
    }

    const onSubmit = async () => {
        if (!data?.id && !data?.regulation?.value) {
            setData((prev) => ({ ...prev, formErrors: { "regulation": "Please select an regulation!" } }))
        }

        // if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
        //     // const rentSetupId = await dispatch(createExternalRentSetupAction(profile.company, data?.regulation, dispatch, setLoading, (res) => {
        //     //     setData((prev) => ({ ...prev, ["id"]: res?.id }))
        //     //     return res?.id
        //     // }))
        //     createTiersSubmit()
        // }

        if (activeIndex === 0) {
            onSubmitTier1()
        } else if (activeIndex === 1) {
            onSubmitTier2()
        } else if (activeIndex === 2) {
            onSubmitTier3()
        } else if (activeIndex === 3) {
            onSubmitTier4()
        }
    }

    const handleSetTiersData = (index, tierData) => {
        const tiers = [...allTiers]
        tiers[index] = tierData
        setAllTiers(tiers)

        // if (tiersArray?.length - 1 > activeIndex) {
        //     setActiveIndex(activeIndex + 1)
        // } 
        handleSetActivetab()
        if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
            createTiersSubmit(tierData, activeIndex)
        }

        const updatedTabs = disableTabs.filter(tab => tab !== activeIndex + 1)
        setDisableTabs(updatedTabs)
    }

    const handleSetActivetab = () => {
        if (tiersArray?.length - 1 > activeIndex) {
            setActiveIndex(activeIndex + 1)
        }
        // else {
        //     setActiveIndex(0)
        // }
    }

    const onSubmitTier1 = async () => {
        const filteredServices = servicesList.map((item) => {
            return { service: item?.id, value: item?.maximumOverride }
        }).filter((item) => item.value)

        const shouldShowAreaSelectionForm = ['base_rent', 'maintenance_rent', 'heating_rent', 'heating_deduction'].some(key => key in data.tiers);
        if (showFormErrors(tierData1, setTierData1) && (data?.regulation?.value || data?.id)) {
            const tierData = {
                tier: "tier1",
                data: tierData1.data,
                area_selection: tierData1?.areaSelection,
                tier_deduction_control: [
                    { type: "base_rent", value: tierData1?.baseRent },
                    { type: "maintenance", value: tierData1?.maintenance },
                ],
                deduction_control: tierData1?.deductionControl ? true : false,
                override_control: tierData1?.overRideControl === "Active" ? true : false,
                tier_modifier_cases: filteredServices,
            }
            if (!shouldShowAreaSelectionForm) {
                delete tierData.area_selection
            }
            if (tierData1?.id) {
                dispatch(updateTierAction(tierData1?.id, tierData, dispatch, setLoading, (res) => {
                    handleSetActivetab()
                }))
            } else {
                handleSetTiersData(activeIndex, tierData)

                // if (tiersArray?.length - 1 > activeIndex) {
                //     setActiveIndex(activeIndex + 1)
                // }
                // if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
                //     createTiersSubmit(tierData, activeIndex)
                // }
                // if (tiersArray?.length - 1 === activeIndex) {
                //     dispatch(addTierAction(tierData, dispatch, setLoading, (res) => {
                //         if (tiersArray?.length - 1 > activeIndex) {
                //             setActiveIndex(activeIndex + 1)
                //             setTierData1((prev) => ({ ...prev, ["id"]: res?.id }))
                //             const updatedTabs = disableTabs.filter(tab => tab !== activeIndex + 1)
                //             setDisableTabs(updatedTabs)
                //         } else {
                //             navigate("/")
                //         }
                //     }))
                // } else {
                //     setActiveIndex(activeIndex + 1)
                // }
            }
        }
    }

    const onSubmitTier2 = () => {
        const shouldShowAreaSelectionForm = ['base_rent', 'maintenance_rent', 'heating_rent', 'heating_deduction'].some(key => key in data.tiers);
        if (showFormErrors(tierData2, setTierData2) && (data?.regulation?.value || data?.id)) {
            const tierData = {
                tier: "tier2",
                data: tierData2.data,
                area_selection: tierData2?.areaSelection,
                override_control: tierData2?.overRideControl === "Active" ? true : false,
            }
            if (!shouldShowAreaSelectionForm) {
                delete tierData.area_selection
            }

            if (tierData2?.id) {
                dispatch(updateTierAction(tierData2?.id, tierData, dispatch, setLoading, (res) => {
                    handleSetActivetab()
                }))
            } else {
                handleSetTiersData(activeIndex, tierData)
                // if (tiersArray?.length - 1 > activeIndex) {
                //     setActiveIndex(activeIndex + 1)
                // }
                // if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
                //     createTiersSubmit(tierData, activeIndex)
                // }
            }
        }
    }

    const onSubmitTier3 = () => {
        const shouldShowAreaSelectionForm = ['base_rent', 'maintenance_rent', 'heating_rent', 'heating_deduction'].some(key => key in data.tiers);
        if (showFormErrors(tierData3, setTierData3) && (data?.regulation?.value || data?.id)) {
            const tierData = {
                tier: "tier3",
                data: tierData3.data,
                area_selection: tierData3?.areaSelection,
                override_control: tierData3?.overRideControl === "Active" ? true : false,
            }
            if (!shouldShowAreaSelectionForm) {
                delete tierData.area_selection
            }
            if (tierData3?.id) {
                dispatch(updateTierAction(tierData3?.id, tierData, dispatch, setLoading, (res) => {
                    handleSetActivetab()
                }))
            } else {
                handleSetTiersData(activeIndex, tierData)
                // if (tiersArray?.length - 1 > activeIndex) {
                //     setActiveIndex(activeIndex + 1)
                // }
                // if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
                //     createTiersSubmit(tierData, activeIndex)
                // }
            }
        }
    }

    const onSubmitTier4 = () => {
        const shouldShowAreaSelectionForm = ['base_rent', 'maintenance_rent', 'heating_rent', 'heating_deduction'].some(key => key in data.tiers);
        if (showFormErrors(tierData4, setTierData4) && (data?.regulation?.value || data?.id)) {
            const tierData = {
                tier: "tier4",
                data: tierData4.data,
                area_selection: tierData4?.areaSelection,
                override_control: tierData4?.overRideControl === "Active" ? true : false,
            }
            if (!shouldShowAreaSelectionForm) {
                delete tierData.area_selection
            }
            if (tierData4?.id) {
                dispatch(updateTierAction(tierData4?.id, tierData, dispatch, setLoading, (res) => {
                    handleSetActivetab()
                }))
            } else {
                handleSetTiersData(activeIndex, tierData)
                // if (tiersArray?.length - 1 === activeIndex && data?.regulation?.value && !data?.id) {
                //     createTiersSubmit(tierData, activeIndex)
                // }
            }
        }
    }

    const createTiersSubmit = (tier, index) => {
        const tiers = [...allTiers]
        tiers[index] = tier
        dispatch(createExternalRentSetupAction(profile?.company, data?.regulation, tiers, dispatch, setLoading, (res) => {
            getExternalRentSetup()
            setAllTiers([])
        }))
    }

    return {
        data,
        handleChange,
        activeIndex,
        setActiveIndex,
        regulationOptions,
        regulationChange,
        onSubmit,
        loading,
        tierData1,
        setTierData1,
        tierData2,
        setTierData2,
        tierData3,
        setTierData3,
        tierData4,
        setTierData4,
        servicesList,
        setServicesList,
        tiersArray,
        disableTabs,
        getExternalRentSetup
    }
}

export default ExternalRentSettingsContainer
