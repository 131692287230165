import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "../../Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import formValidation from "utils/validations";
import {
  addHandymanServiceAction,
  archiveHandymanServiceAction,
  // deleteHandymanServiceAction,
  getHandymanServiceListAction,
  updateHandymanServiceAction,
} from "store/actions/handymanServiceAction";
import { showFormErrors } from "utils/commonFunctions";
import ArchiveIcon from "assets/images/icons/archive.svg"
import { showToastAction } from "store/slices/commonSlice";

const HandymanServiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [selectedService, setSelectedService] = useState("");
  const [selectedServices, setSelectedServices] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [addService, setAddService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState();
  // const { landlord } = useSelector((state) => state?.landlord);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);
  const [baseStatus, setBaseStatus] = useState("")
  const [serviceData, setServiceData] = useState({
    serviceName: "",
  });
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });

  const handleServiceChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, serviceData);
    setServiceData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleAddService = () => {
    if (showFormErrors(serviceData, setServiceData)) {
      if (selectedService) {
        dispatch(
          updateHandymanServiceAction(
            profile?.company,
            selectedService,
            serviceData,
            setLoading,
            dispatch,
            (res) => {
              setAddService(false);
              setSelectedService("");
              getServiceList(profile?.company, rows, page, search, baseStatus);
              setServiceData({ serviceName: "" });
            }
          )
        );
      } else {
        dispatch(
          addHandymanServiceAction(
            profile?.company,
            serviceData,
            setLoading,
            dispatch,
            (res) => {
              setAddService(false);
              getServiceList(profile?.company, rows, page, search, baseStatus);
              setServiceData({ serviceName: "" });
            }
          )
        );
      }
    }
  };

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              debouncedOnChange.current(profile?.company, rows, page, value, baseStatus);
              setFirst(0);
            }}
            isSearch={isSearch}
            placeholder="Search Service"
          />
        </div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Service"
          onClick={() => {
            setAddService(true);
          }}
        />
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedServices([])
                getServiceList(
                  profile?.company,
                  10,
                  1,
                  "",
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedServices, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedServices?.length || 0})`}
              </li></> : <><li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setBaseStatus("archived")
                  setSelectedServices([])
                  getServiceList(
                    profile?.company,
                    10,
                    1,
                    "",
                    "archived"
                  );
                }}
              >
                <img src={ArchiveIcon} />
                Show Archived
              </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedServices, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedServices?.length || 0})`}
              </li></>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Service Name",
        accessor: "name",
        value: true,
        frozen: true,
      },
    ],
    []
  );

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              setAddService(true);
              setServiceData({ serviceName: d?.name });
              setSelectedService(d?.id);
            }}
          >
            <i className="pi pi-file-edit text-dark cursor-pointer"></i>
            Edit
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
              setDeleteModal(true);
            }}
          >
            <img src={ArchiveIcon} />
            {d?.base_status === "archived" ? "Unarchive" : "Archive"}
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "handyman services" : "handyman service"} from the
        list?`}
      </span>
    );
  };

  const addServiceModal = () => {
    return (
      <CustomInput
        extraClassName={"w-full"}
        data={serviceData}
        name="serviceName"
        onChange={handleServiceChange}
        required
      />
    );
  };

  const handleServiceDelete = () => {
    dispatch(
      archiveHandymanServiceAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          setSelectedService("");
          getServiceList(profile?.company, rows, page, search, baseStatus);
          setSelectedServices([])
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
        // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} /> */}
          {/* <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const getServiceList = (company, rows, page, search, baseStatus) => {
    dispatch(
      getHandymanServiceListAction(company, rows, page, search, baseStatus, (res) => {
        setServiceList(res?.options);
        setCount(res?.count);
        setIsSearch(false);
        // navigate(`/settings/handyman-services?page=${page}&rows=${rows}`);
      })
    );
  };

  const handleSearch = (company, rows, page, search, baseStatus) => {
    setIsSearch(true);
    getServiceList(company, rows, page, search, baseStatus);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company, 10, 1, search, baseStatus);
      navigate(`/settings/handyman-services?page=${1}&rows=${10}`);
    }
  }, [profile?.company]);

  const onPageChange = (e) => {
    navigate(`/settings/handyman-services?page=${e.page + 1}&rows=${e.rows}`);
    getServiceList(profile?.company, e.rows, e.page + 1, search, baseStatus);
    setFirst(e.first);
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  return (
    <div>
      <CustomCard title="Handyman Services" header={header()}>
        <CustomTableContainer
          list={serviceList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          selectedItems={selectedServices}
          setSelectedItems={setSelectedServices}
          // activeRow={landlord?.id}
          // slice={setLandlordSlice}
          globalFilters={[]}
          handleSort={""}
          onPageChange={onPageChange}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={idToDelete?.action === "archived" ? "Archive" : "Unarchive"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleServiceDelete}
        loading={loading}
      />
      <CustomModal
        isOpen={addService}
        setIsOpen={setAddService}
        heading={selectedService ? "Edit Service" : "Add Service"}
        body={addServiceModal}
        submitLabel={selectedService ? "Save Changes" : "Save"}
        onSubmit={handleAddService}
        loading={loading}
        onCancel={() => setServiceData({ serviceName: "" })}
      />
    </div>
  );
};

export default HandymanServiceList;
