import React, { useRef, useState } from "react";
import {
  CustomAmountInput,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
} from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoomAction,
  updateRoomAction,
} from "store/actions/buildingUnitActions";
import { handleDelete } from "shared/Components/commonFunctions";
import { Toast } from "primereact/toast";
import { checkIsValueGreater } from "utils/commonFunctions";
import { getPorpertyInfoAction } from "store/actions/propertyActions";
import { useParams } from "react-router-dom";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import SizeM2 from "assets/images/svg/SizeM2.svg";
import { CustomNumberViewInput } from "shared/AllViewInputs";
export default function CustomRoomTable({
  id,
  maxSize,
  title,
  allRooms,
  setAllRooms,
}) {
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state.user);

  const rowEditValidator = async (rowData, options) => {
    const { doors, size, name } = rowData;
    const doorError = formValidation("doors", doors, allRooms, ["name"]);
    const sizeError = formValidation("size", size, allRooms, ["name"]);
    const isGreater = checkIsValueGreater(size, maxSize);

    if (!doorError["doors"] && !sizeError["size"] && !isGreater) {
      let payload = {
        doors: doors,
        size: size,
        name: name,
        building_unit: id,
      };
      dispatch(
        updateRoomAction(rowData?.id, payload, dispatch, (res) => {
          let rooms = [...allRooms];
          rooms[options.rowIndex] = rowData;
          setAllRooms(rooms);
          setEditingRows({});
          dispatch(
            getPorpertyInfoAction(profile?.company, params?.id, dispatch)
          );
          return true;
        })
      );
    } else {
      return false;
    }
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const sizeEditor = (options) => {
    const filterdRooms = allRooms.filter(
      (item) => item?.id !== options?.rowData?.id
    );
    const totalSize = filterdRooms.reduce((acc, obj) => {
      return acc + parseFloat(obj?.size);
    }, 0);

    const total = totalSize + parseFloat(options.value);
    const formErrors = formValidation("size", options.value, allRooms);

    if (checkIsValueGreater(total, maxSize)) {
      formErrors["size"] = "The room size must be lower than the unit size.";
    }

    return (
      <>
        {/* <CustomSizeM2
          type="text"
          col={12}
          value={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}
          name="size"
          ignoreLabel
          max={9}
          errorMessage={formErrors["size"]}
          spanIcon={SizeM2}
        /> */}
        <CustomAmountInput
          type="text"
          col={12}
          value={options.value}
          onChange={({ value }) => options.editorCallback(value)}
          name="size"
          ignoreLabel
          max={9}
          errorMessage={formErrors["size"]}
          spanIcon={SizeM2}
        />
      </>
    );
  };

  const DoorEditor = (options) => {
    const formErrors = formValidation("doors", options.value, allRooms);
    return (
      <CustomNumberInput2
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="doors"
        ignoreLabel
        errorMessage={formErrors["doors"]}
        max={9}
      />
    );
  };

  const nameEditor = (options) => {
    const formErrors = formValidation(
      "name",
      options.value,
      allRooms,
      [""],
      ["name"]
    );
    return (
      <CustomInput
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="name"
        ignoreLabel
        errorMessage={formErrors["name"]}
      />
    );
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              className="cursor-pointer"
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => {
                    setIdToDelete({
                      id: rowData?.id,
                      index: options?.rowIndex,
                    });
                    setDeleteModal(true);
                  }}
                >
                  <i className="pi pi-trash  text-dark"></i> Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected room from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteRoom = () => {
    setLoading(true);
    dispatch(
      deleteRoomAction(idToDelete?.id, setLoading, dispatch, (res) => {
        handleDelete(allRooms, setAllRooms, "", "", idToDelete?.index);
        dispatch(getPorpertyInfoAction(profile?.company, params?.id, dispatch));
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
      })
    );
  };

  return (
    <CustomForm title={title}>
      <Toast ref={msgs} />
      <DataTable
        className="col-12 properties-table-list table-error-align"
        value={allRooms}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          field="srNo"
          header="Room ID"
          headerClassName="custom-header"
          style={{ width: "20%" }}
        ></Column>
        <Column
          field="doors"
          header="Doors"
          editor={(options) => DoorEditor(options)}
          headerClassName="custom-header"
          style={{ width: "24%" }}
        ></Column>
        <Column
          field="size"
          header="Size"
          headerClassName="custom-header"
          body={(rowData) => <CustomNumberViewInput value={rowData.size} name="size" spanIcon={SizeM2} ignoreLabel />}
          // body={(rowData) => (
          //   <span>
          //     {rowData.size} m<sup>2</sup>
          //   </span>
          // )}
          editor={(options) => sizeEditor(options)}
          style={{ width: "24%" }}
        ></Column>
        <Column
          field="name"
          header="Name"
          headerClassName="custom-header"
          editor={(options) => nameEditor(options)}
          style={{ width: "24%" }}
        ></Column>
        <Column
          header="Action"
          rowEditor
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          headerClassName="custom-header"
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteRoom}
        loading={loading}
      />
    </CustomForm>
  );
}
