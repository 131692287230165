import React, { useMemo } from "react";
import { CustomAmountViewInput, CustomNumberViewInput, CustomViewInput } from "shared/AllViewInputs";
import CommonViewTable from "views/Tables/CommonViewTable";
import { getTenantName } from "../externalRentFunctions";
import SizeM2 from "assets/images/sizem2.svg"

const BaserentTable = ({ items }) => {
    const columns = useMemo(() => [
        {
            name: "No. of Tenants",
            accessor: "number_of_tenant",
            value: true,
            body: (options) => <CustomViewInput value={getTenantName(options.number_of_tenant)} />
        },
        {
            name: "Abtracted Allowed Size",
            accessor: "abstract_allowed_size",
            value: true,
            body: (options) => <CustomNumberViewInput value={options.abstract_allowed_size} spanIcon={SizeM2} />
        },
        {
            name: "Net Rent by Sqm",
            accessor: "net_rent",
            value: true,
            body: (options) => <CustomAmountViewInput value={options.net_rent} />
        },
        {
            name: "Gross Base Rent",
            accessor: "gross_base_rent",
            value: true,
            body: (options) => <CustomAmountViewInput value={options.gross_base_rent} />
        },
    ], [])

    return (
        <CommonViewTable
            title={"Base Rent"}
            list={items}
            columns={columns}
        />
    )
}

export default BaserentTable