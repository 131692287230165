import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import classNames from "classnames";
import { canAccess } from "services/auth";
import { useSelector } from "react-redux";

export default function SidebarItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const SVG = props.Svg;
  const ActiveSVG = props.ActiveSvg;
  const _title = props.title;
  const _items = props.items;
  const _onClick = props.onClick;
  const _link = props.link;
  const [isOpen, setIsOpen] = useState(false);
  const { profile } = useSelector((state) => state?.user);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onItemClick = () => {
    if (_items && _items.length) {
      handleToggle();
      return;
    }
    if (_link) {
      navigate(_link);
      return;
    }

    if (_onClick) {
      _onClick();
      return;
    }
  };

  const getActive = (path) => {
    const activePath = location.pathname.split('/').filter(Boolean)[0]
    return path === `/${activePath}` || _title.toLowerCase() === activePath;
  };

  const checkParentRoute = () => {
    if (_title === "Logout") {
      return true;
    } else if (_items) {
      let codenames = [];
      _items.map((routes) => {
        codenames = routes?.items?.map((item) => {
          return item?.codename;
        });
      });

      if (!codenames) {
        return true;
      }
      const hasPermission = codenames?.some((item) => canAccess(item));
      return hasPermission;
    }
    // else{
    //  return true //remove
    // }
  };

  useEffect(() => {
    checkParentRoute();
  }, [profile]);
  
  return (
    <>
      {checkParentRoute() && (
        <div
          className={classNames("sidebar-item flex justify-content-between", {
            active: isOpen || getActive(_link),
          })}
          onClick={onItemClick}
        >
          <div className="flex">
            {(getActive(_link) || isOpen) ? <ActiveSVG /> : <SVG />}
            &nbsp; &nbsp;
            <div>{_title}</div>
          </div>
          {_items && _items.length && (
            <>
              {/* <i
              className={classNames("open-icon pi pi-caret-right my-auto", {
                open: isOpen,
              })}
            ></i> */}
              {/* <div
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
                // display: "inline-block",
              }}
            > */}
              <svg
                style={{
                  transform: isOpen ? "rotate(180deg)" : "",
                  // display: "inline-block",
                }}
                width="25"
                height="25"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1117 11.8978L5.19629 6.98242L15.0271 6.98242L10.1117 11.8978Z"
                  fill="white"
                />
              </svg>
              {/* </div> */}
            </>
          )}
        </div>
      )}
      {isOpen && (
        <div className="menu-items">
          {_items.map((item, i) => (
            <MenuItem
              key={i}
              Svg={item?.icon}
              title={item?.title}
              items={item?.items}
              link={item?.link ? _link + item?.link : _link}
              codename={item?.codename}
            />
          ))}
        </div>
      )}
    </>
  );
}
