import React, { useState } from "react";
import { CustomForm } from "shared/AllInputs";
import CustomCard from "shared/CustomCard";
import UserAvatar from "assets/images/icons/profile-circle.svg"
import EditIcon from "assets/images/icons/editicon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomButtonOutlinedDanger } from "shared/CustomButton";
import CustomModal from "shared/Modals/CustomModal";
import { deleteAccountAction, getProfileAction } from "store/actions/userActions";
import { Avatar } from "primereact/avatar";

const ViewProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { profile } = useSelector((state) => state?.user)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const header = (redirectTo) => {
        return (
            <div className="flex justify-content-around cursor-pointer">
                <img
                    src={EditIcon}
                    alt=""
                    onClick={() =>
                        navigate(redirectTo)
                    }
                ></img>
            </div>
        );
    };

    const modalContent = () => {
        return (
            <span>
                Are you sure you want to delete your account?
                Please note, your account will be permanently deleted within the next 30 days.
            </span>
        );
    };

    const handleDeleteAccount = () => {
        dispatch(deleteAccountAction(setLoading, dispatch, navigate))
    }

    return (
        <>
            <CustomCard>
                <CustomForm title="Personal Details" header={header("edit")}>
                    <div>
                        <div className="p-2 w-full flex align-items-center">
                            <Avatar image={profile?.profile_photo || UserAvatar} shape="circle" style={{width:"90px",height: "90px"}} />
                            <div className="mt-5 ml-3">
                                <div className="mb-1 font-semibold capitalize">{profile?.first_name} {profile?.last_name}</div>
                                <div className="mb-1 firstLetterCapital">{profile?.email}</div>
                            </div>
                        </div>
                    </div>
                </CustomForm>
            </CustomCard>
            <br />
            <CustomCard>
                <CustomForm title="Change Password" header={header("change-password")}>
                    <div className="p-2 w-full">
                        <div className="mb-1 font-semibold">Password</div>
                        <div className="mb-1">*******</div>
                    </div>
                </CustomForm>
            </CustomCard>
            <br />
            <CustomCard>
                <CustomForm title="Delete Account">
                    <div className="p-2 w-full">
                        <CustomButtonOutlinedDanger label="Delete Account" icon="pi pi-trash text-dark" onClick={() => setIsOpen(true)} />
                    </div>
                </CustomForm>
            </CustomCard>
            <CustomModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                heading={"Delete Account"}
                body={modalContent}
                submitLabel={"Confirm"}
                onSubmit={handleDeleteAccount}
                loading={loading}
            />
        </>
    )
}

export default ViewProfile