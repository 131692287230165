import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import LandlordInfo from "../../../CommonComponents/LandlordInfo";
import LandlordContainer from "./LandlordContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import AddContacts from "shared/Components/AddContacts";
import AddBankAccounts from "shared/Components/AddBankAccounts";

const LandlordForm = ({ type }) => {
  const {
    data,
    handleChange,
    allContacts,
    setAllContacts,
    setLandlordAddress,
    landlordIdChange,
    landlordOptions,
    navigate,
    onSubmit,
    loading,
    isSearch,
    allBankAccounts,
    setAllBankAccounts,
    landlordId
  } = LandlordContainer();

  return (
    <Page
      title={`${type} landlord`}
      description={`Let’s ${type} your landlord Details`}
      navigateLink={`/real-estate/others/landlord/list`}
    >
      <CustomCard>
        <LandlordInfo
          type={"new"}
          data={data}
          handleChange={handleChange}
          landlordOptions={landlordOptions}
          landlordIdChange={landlordIdChange}
          setLandlordAddress={setLandlordAddress}
          isSearch={isSearch}
        />
        <AddContacts
          title="Contacts"
          allContacts={allContacts}
          setAllContacts={setAllContacts}
        />
        <br/>
        <AddBankAccounts
          landlordId={landlordId}
          title="Banking Details"
          mode={landlordId? "edit" : "add"}
          allBankAccounts={allBankAccounts}
          setAllBankAccounts={setAllBankAccounts}
        />
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label={type === "Edit" ? "Save Changes" : "Save"} loading={loading} />
        <CustomButtonOutlined
          label="Cancel"
          onClick={() => {
            navigate(-1);
          }}
        />
      </Buttonlayout>
    </Page>
  );
};

export default LandlordForm;
