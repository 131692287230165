import React, { useMemo } from "react";
import SizeM2 from "assets/images/svg/SizeM2.svg";
import { CustomAmountViewInput, CustomNumberViewInput } from "shared/AllViewInputs";
import CommonViewTable from "views/Tables/CommonViewTable";
export default function HeatingCostTable({ items }) {

    const columns = useMemo(
        () => [
            {
                name: "Size From",
                accessor: "size_from",
                value: true,
                body: (options) => <CustomNumberViewInput value={options.size_from} spanIcon={SizeM2} />,
                style: { minWidth: "220px" }
            },
            {
                name: "Size To",
                accessor: "size_to",
                value: true,
                body: (options) => <CustomNumberViewInput value={options.size_to} spanIcon={SizeM2} />,
                style: { minWidth: "220px" }
            },
            {
                name: "Cost by sqm(Oil)",
                accessor: "cost_oil",
                value: true,
                body: (options) => <CustomAmountViewInput value={options?.cost_oil} />,
                style: { minWidth: "220px" }
            },
            {
                name: "Cost by sqm(Gas)",
                accessor: "cost_gas",
                value: true,
                body: (options) => <CustomAmountViewInput value={options?.cost_gas} />,
                style: { minWidth: "220px" }
            },
            {
                name: "Cost by sqm(Electricity)",
                accessor: "cost_electricity",
                value: true,
                body: (options) => <CustomAmountViewInput value={options?.cost_electricity} />,
                style: { minWidth: "220px" }
            },
        ],
        []
    );

    return (
        <CommonViewTable
            title={"Heating Cost"}
            list={items}
            columns={columns}
        />
    );
}
