import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "shared/AllInputs";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";
import { showFormErrors } from "utils/commonFunctions";
import AvatarImg from "assets/images/icons/profile-circle.svg";
import { useNavigate } from "react-router-dom";
import formValidation from "utils/validations";
import { updateProfileAction } from "store/actions/userActions";
import { Avatar } from "primereact/avatar";

const EditProfile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { profile } = useSelector((state) => state?.user)
    const [data, setData] = useState({ firstName: "", lastName: "", email: "", file: "" })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (profile?.first_name) {
            setData({
                firstName: profile.first_name,
                lastName: profile?.last_name,
                email: profile?.email,
                file: profile?.profile_photo,
            })
        }
    }, [profile])

    const onSubmit = () => {
        if (showFormErrors(data, setData, ["email"])) {
            dispatch(updateProfileAction(data, setLoading, dispatch, navigate))
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setData((prev) => ({ ...prev, file: file }))
        }
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data, ["email, file"])
        setData((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    return (
        <div className="flex justify-content-center align-items-center h-30rem ">
            <div className="lg:w-6 bg-white p-4 border-round-lg shadow-1">
                <div className={`flex gap-2 justify-content-between flex-wrap`}>
                    <div className="title my-auto">Edit Profile</div>
                </div>
                <div className="avatar-upload cursor-pointer" onClick={() => document.getElementById('file-input').click()}>
                    {data?.file?.name ? <img className="image-view" src={URL.createObjectURL(data?.file)} alt="" /> : <Avatar image={data?.file || AvatarImg} alt="" shape="circle" style={{width:"100px",height:"100px"}} />}
                    <div className="overlay-upload">
                        <i className="pi pi-camera"></i>
                    </div>
                    <input
                        id="file-input"
                        type="file"
                        style={{ display: 'none' }}
                        accept={".jpg,.jpeg,.png"}
                        onChange={handleImageChange}
                    />
                </div>
                <CustomInput name="firstName" data={data} ignoreLabel col={12} onChange={handleChange} />
                <CustomInput name="lastName" data={data} ignoreLabel col={12} onChange={handleChange} />
                <CustomInput name="email" data={data} disabled ignoreLabel col={12} />
                <Buttonlayout className="flex-column">
                    <CustomButton label="Save Changes" extraClassNames={'w-full my-3'} onClick={onSubmit} loading={loading} />
                    <CustomButtonOutlined label="Cancel" extraClassNames={'w-full'} onClick={() => navigate("/settings/profile")} />
                </Buttonlayout>
            </div>
        </div>
    )
}

export default EditProfile