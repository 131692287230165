import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomForm } from "shared/AllInputs";
import EditIcon from "assets/images/icons/editicon.png";
import { CustomViewInput } from "shared/AllViewInputs";
import BuildingCotainer from "./BuildingContainer";
import { canAccess } from "services/auth";
import ViewAddressComponent from "shared/Components/ViewAddressComponent";

export default function ViewBuilding() {
  const { data, navigate } = BuildingCotainer();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/others/building/edit/${data?.building}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <Page
      title={`View building`}
      description={`Let’s View your building Details`}
    >
      <CustomCard>
        <CustomForm title="Building" header={canAccess("change_building") &&  header()}>
          {/* <CustomViewInput name="address" data={data} /> */}
          <ViewAddressComponent data={data} />
          <CustomViewInput name="buildingType" data={data} />
          <CustomViewInput name="buildingMaxFloor" data={data} />
          <CustomViewInput name="staircaseAccess" data={data} />
          <CustomViewInput
            name="size"
            data={data}
            spanLabel={
              <span>
                m<sup>2</sup>
              </span>
            }
          />
        </CustomForm>
      </CustomCard>
    </Page>
  );
}
