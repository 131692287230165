import React from "react";
import { CustomForm } from "shared/AllInputs";
import {
  CustomAmountViewInput,
  CustomDateViewInput,
  CustomNumberViewInput,
  CustomViewInput,
} from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "assets/images/icons/editicon.png";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { TabPanel, TabView } from "primereact/tabview";
import CustomMaturityTable from "views/Tables/CustomMaturityTable";
import ViewContractContainer from "./ViewContractContainer";
import CustomPropertyTable from "views/Tables/CustomPropertyTable";
import { canAccess } from "services/auth";
import ViewRentandCost from "views/CommonViewComponents/RentAndCost";
import EuroIcon from "assets/images/svg/euroIcon.svg"
import { reformatDateString } from "utils/commonFunctions";

const ViewContractForm = () => {
  const {
    data,
    allMaturities,
    setAllMaturities,
    allExtraCost,
    setAllExtraCost,
    propertyList,
    activeIndex,
    handleTabChange,
  } = ViewContractContainer();

  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/others/contract/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <Page
      title="View Details"
      description={`Let’s View your Contract Details`}
      navigateLink={"/real-estate/others/contract/list"}
    >
      <CustomCard>
        <CustomForm title="Contractual Info" header={canAccess("add_contract") && header()}>
          <CustomNumberViewInput
            name="contract_id"
            data={data}
            label={"Contract ID"}
          />
          {/* <CustomViewInput name="estate_company" data={data} /> */}
          <CustomNumberViewInput
            name="size"
            data={data}
            label="Contractual Size"
          />
          <CustomNumberViewInput name="room" data={data} label="Bedroom" />
          <CustomViewInput
            name="landlord_name"
            value={data?.landlord_name}
            label="Selected Landlord"
          />
        </CustomForm>
        <TabView 
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Rent & Cost">
            <ViewRentandCost
              title="Rent & Cost"
              type="contract"
              isEditable={false}
              contractId={data?.id}
              data={data}
              allExtraCost={allExtraCost}
              setAllExtraCost={setAllExtraCost}
            />
          </TabPanel>
          <TabPanel header="Maturity">
            <CustomForm title="Maturity">
              <CustomViewInput
                name="maturity_type"
                data={data}
                label={"Type of Maturity"}
              />
              {data?.maturity_type === "None" ||
              data?.maturity_type === null ? null : (
                <>
                  <CustomDateViewInput
                    name="maturity_date"
                    value={reformatDateString(data?.maturity_date)}
                    label="First Maturity Date"
                  />
                  {data?.maturity_type === "Automatic Renewal" && (
                    <>
                      {" "}
                      <CustomNumberViewInput
                        name="maturity_extension"
                        data={data}
                       
                      />
                      <CustomNumberViewInput
                        name="maturity_notice_period"
                        data={data}
                        label="Notice Period"
                      />
                    </>
                  )}
                </>
              )}
              {data?.maturity_type === "Optional Renewal" ? (
                <>
                  <CustomMaturityTable
                    action="contract"
                    id={data?.id}
                    title={"Optional Maturity"}
                    allMaturities={allMaturities}
                    setAllMaturities={setAllMaturities}
                  />
                </>
              ) : null}
            </CustomForm>
          </TabPanel>
          <TabPanel header="Deposit">
            <CustomForm title="Deposits">
              <CustomViewInput name="deposit_type" data={data} />
              {(data?.deposit_type !== "None" && data?.deposit_amount )? (
                <>
                  <CustomAmountViewInput
                    name="deposit_amount"
                    data={data}
                    icon={EuroIcon}
                  />
                </>
              ) : (
                data?.deposit_type !== "None" && (
                  <CustomAmountViewInput
                    inputClass="pl-5"
                    name="deposit_amount"
                    data={data}
                  />
                )
              )}
            </CustomForm>
          </TabPanel>
          <TabPanel header="Property">
            <CustomForm title={"Property List"} />
              <CustomPropertyTable 
                allProperties={propertyList}
              />
            {/* </CustomForm> */}
          </TabPanel>
        </TabView>
      </CustomCard>
    </Page>
  );
};

export default ViewContractForm;
