import { Tooltip } from 'primereact/tooltip';
const BigNumber = require('bignumber.js'); // For Node.js environment
export const alphabeticStringValidation = (val) => {
  const regex = /^[a-zA-z]+([\s][a-zA-Z]+)*$/;
  return regex.test(val);
};

export const spaceNotAllowed = (val) => {
  const regex = /^(?!.*\s).+$/;
  return regex.test(val);
};


export const regularString = (val) => {
  let trimed = val
  if (typeof val !== 'object' && isNaN(val)) {
    trimed = val?.trim() || val
  }
  const regex =
    /^[\w!@#$%^&*()\-=_+{}[\]|;:'",.<>/?öäüÖÄÜß]+(?: [\w!@#$%^&*()\-=_+{}[\]|;:'",.<>/?öäüÖÄÜß]+)*$/;
  return regex.test(trimed);
};

export const stringSpace = (val) => {
  const regex = /^(?=.*[a-zA-Z])[a-zA-Z ]*$/
  return regex.test(val)
}

export const stringNumberSpace = (val) => {
  const regex = /^(?=.*[a-zA-Z0-9öäüÖÄÜß])[a-zA-Z0-9öäüÖÄÜß ]*$/
  return regex.test(val)
}

export const officeNameRegex = (val) => {
  const regex = /^(?=.*[a-zA-ZöäüÖÄÜß])[a-zA-Z0-9öäüÖÄÜß #@&_.-]*$/
  return regex.test(val)
}

export const addressRegex = (val) => {
  const regex = /^(?=.*[a-zA-Z0-9öäüÖÄÜß])[a-zA-Z0-9öäüÖÄÜß\s\,\#\.\''\-]*(?!\s)*[a-z A-Z0-9öäüÖÄÜß].*$/
  return regex.test(val)
}

export const stringValidation = (val) => {
  const regex = /^[a-zA-Z0-9öäüÖÄÜß_.-]*$/;
  return regex.test(val);
};

export const onlyAlphabeticStringValidation = (val) => {
  const regex = /^[A-Za-zöäüÖÄÜß]+$/;
  return regex.test(val);
};

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
};

export const phoneValidation = (phone) => {
  const regex =
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
  return regex.test(phone.toLowerCase());
};

export const passwordValidation = (password) => {
  // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  const regex = /^(?=.*\d)(?=.*[!@#$%^&*öäüÖÄÜß])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
  return regex.test(password);
};

export const spaceBetweenWords = (word) =>
  word.replace(/([a-z])([A-Z])/g, "$1 $2");

export const number = (value) => {
  const regex = /^(?=.*\d)[\d,.]+$/
  // const regex = /^(?=\d)(\d{1,3}(?:[,\.]\d{3})*)?(?:[\.]?\d{0,2})?$/;
  // const regex = /^(?=\d)(\d{1,3}(?:[\.,]\d{3})*)?(?:[\,]?\d{0,2})?$/;
  // const regex = /^(?=\d)(\d{1,3}(?:\.\d{3})*)(?:,\d{1,2})?$/;
  return regex.test(value);
};

export const numberOnly = (value) => {
  // const regex = /^[0-9]+$|^$/;
  // const regex = /^([0-9]+(,[0-9]+)?)?$/
  // const regex = /^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/
  const regex = /^\d+$/
  return regex.test(value);
};

export const maxNumbers = (value) => {
  const regex = /^[\d.,]{1,9}$/
  // const regex = /^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/
  // const regex = /^(0|[1-9][0-9]{0,5}|[1-9][0-9]{6}|1000000)(,[0-9]{1,2})?$/;
  return regex.test(value);
}

export const floatFromString = (value) => {
  const regex = /[+-]?\d+(\.\d+)?/g;
  return value.match(regex).map(function f(v) {
    return parseFloat(v);
  });
};

//capital every words firstletter
export const firstLetterToUppercase = (value) => {
  if (value) {
    return spaceBetweenWords(value.replace(/\b\w/g, (c) => c.toUpperCase()));
  }
}
//capital only firtletter of whole word
export const capitalFirstLetter = (value) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  } else {
    return value
  }
}

export const phoneNumberValidation = (value) => {
  const regex = /^([+]\d{2})?\d{10}$/;
  return regex.test(value);
}

export const zipCodeValidation = (value) => {
  // const regex = /^0\d{4}$/;
  const regex = /^\d{5}$/;
  return regex.test(value);
}

export const specialCharacters = (value) =>
  /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(value);

export const formatFieldName = (value) => {
  return value
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Convert camelCase to space-separated
    .replace(/_/g, ' ') // Convert snake_case to spaces
    .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
    .replace(/\b\w/g, char => char.toUpperCase())
}

export const formatCharFieldName = (value) => {
  return value
    .replace(/(\d)/, ' $1')// Convert camelCase to space-separated
    .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
    .replace(/\b\w/g, char => char.toUpperCase())
}


export const AllLettersUpercase = (value) => {
  if (value) {
    return value.toUpperCase()
  }
}

export const ibanCheck = (iban) => {
  // Regex for format check
  const formatRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;

  // Check format
  if (!formatRegex.test(iban)) {
    return false;
  }

  // Rearrange IBAN
  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

  // Convert letters to numbers
  const ibanNumeric = rearrangedIban.split('').map(char => {
    if (char >= 'A' && char <= 'Z') {
      return (char.charCodeAt(0) - 55); // A=10, B=11, ..., Z=35
    } else {
      return char;
    }
  }).join('');

  // Perform modulus 97 check using BigNumber
  const ibanNumber = new BigNumber(ibanNumeric);
  const mod97 = ibanNumber.mod(97).toNumber();

  return mod97 === 1;
}

export const truncateText = (text, length) => {
  if (text && length) {
    return text.length > length ? text.substring(0, length) + '...' : text;
  }
};

export const truncateTextAndTooltip = (id, text, length) => {
  if (text && length) {
    // return text.length > length ? text.substring(0, length) + '...' : text;
    return (
      <div>
        {/* <Tooltip
          className="tooltip"
          target={`.${id}`}
          content={text}
        /> 
        <span
          className={`${id} cursor-pointer`}
          data-pr-classname="tooltip"
        > */}
        {/* <span
            // className={{
            //   color: selectedItems.some((item) => item?.id == options?.id)
            //     ? "text-blue-700"
            //     : null,
            // }}
            // style={{
            //   color: options?.id === handyman?.id ? "white" : null,
            // }}
          > */}
        {text.length > length ? text.substring(0, length) + '...' : text}
        {/* </span> */}
        {/* </span> */}
      </div>
    );
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

export const allowCharOrNum = (value) => {
  const regex = /^(?=.*[a-zA-Z0-9öäüÖÄÜß])[a-zA-Z0-9öäüÖÄÜß #@&_.-]*$/
  return regex.test(value)
}