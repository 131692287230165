import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveContractAction,
  contractListAction,
} from "store/actions/contractActions";
import { debounce } from "lodash";
import { convertAmountInGerman } from "utils/commonFunctions";
import { DepositType, MaturityType, RentType } from "shared/AllDropDownOptions";
import { setContractSlice } from "store/slices/contractSlice";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { canAccess } from "services/auth";
import ArchiveIcon from "assets/images/icons/archive.svg"
import { showToastAction } from "store/slices/commonSlice";

const ContractList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState({ id: "", action: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  // const [sortField, setSortField] = useState({ field: "", order: "" });
  const [loading, setLoading] = useState(false);
  const contract = useSelector((state) => state?.contract?.contract);
  const globalFilters = [
    "contract_id",
    "maturity_type",
    "rent_rise_type",
    "deposit_type",
  ];
  const [contractList, setContractList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    contract_id: "",
    maturity_type: "",
    rent_rise_type: "",
    deposit_type: "",
  });
  const [filters2, setFilters2] = useState({
    contract_id: { value: "" },
    maturity_type: { value: "" },
    rent_rise_type: { value: "" },
    deposit_type: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [selectedItems, setSelectedItems] = useState();
  const [columns, setColumns] = useState([]);
  const [baseStatus, setBaseStatus] = useState("")

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-8 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.contract_id,
                filters?.maturity_type,
                filters?.rent_rise_type,
                filters?.deposit_type
              );
              setFirst(0);
            }}
            isSearch={isSearch}
            placeholder="Search Contract"
          />
        </div>
        {canAccess("add_contract") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Contract"
            onClick={() => navigate("/real-estate/others/contract/add")}
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedItems([])
                getContractList(
                  profile?.company,
                  10,
                  1,
                  '',
                  filters?.contract_id,
                  filters?.maturity_type,
                  filters?.rent_rise_type,
                  filters?.deposit_type,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedItems?.length || 0})`}
              </li>
            </> : <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("archived")
                setSelectedItems([])
                getContractList(
                  profile?.company,
                  10,
                  1,
                  '',
                  filters?.contract_id,
                  filters?.maturity_type,
                  filters?.rent_rise_type,
                  filters?.deposit_type,
                  "archived"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Show Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedItems?.length || 0})`}
              </li>
            </>}
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const ContractIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="contract_id"
          onChange={({ name, value }) => {
            options.filterCallback(value);
            handleFilterChange({ name: name, value: value })
          }}
          placeholder="Enter Contract ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const MaturityTypeFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          value={options.value}
          options={MaturityType}
          // onChange={(e) => {
          //   options.filterCallback(e.value);
          // }}
          onChange={({ name, value }) => {
            options.filterCallback(value);
            handleFilterChange({ name: name, value: value })
          }}
          col={12}
          name="maturity_type"
          ignoreLabel
          optionLabel="name"
          placeholder="Enter Type of Maturity"
          className="p-column-filter"
        />
      </div>
    );
  };

  const RentRiseTypeFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          value={options.value}
          options={RentType}
          // onChange={(e) => {
          //   options.filterCallback(e.value);
          // }}
          onChange={({ name, value }) => {
            options.filterCallback(value);
            handleFilterChange({ name: name, value: value })
          }}
          name="rent_rise_type"
          col={12}
          optionLabel="name"
          ignoreLabel
          placeholder="Select Rent Rise Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  const DepositTypeFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          value={options.value}
          options={DepositType}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          col={12}
          name="deposit_type"
          ignoreLabel
          optionLabel="name"
          placeholder="Select Deposit Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Contract ID",
        accessor: "contract_id",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        disabled: true,
        filterElement: (option) => ContractIDFilter(option),
      },
      {
        name: "Type of maturity",
        accessor: "maturity_type",
        value: true,
        filter: true,
        filterElement: (options) => MaturityTypeFilter(options),
      },
      {
        name: "Landlord",
        accessor: "landlord_name",
        sortable: false,
        value: true,
      },
      {
        name: "No of Property",
        accessor: "property_count",
        sortable: false,
        value: true,
      },
      {
        name: "Amount",
        accessor: "rent_amount",
        sortable: false,
        value: true,
        body: (options) => convertAmountInGerman(options?.rent_amount),
      },
      {
        name: "Size",
        accessor: "size",
        sortable: false,
        value: false,
      },
      {
        name: "Rent Rise Type",
        accessor: "rent_rise_type",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => RentRiseTypeFilter(options),
      },
      {
        name: "Amount First Month",
        accessor: "rent_amount_first_month",
        sortable: false,
        value: false,
      },
      {
        name: "Deposit Type",
        accessor: "deposit_type",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => DepositTypeFilter(options),
      },
    ],
    []
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/others/contract/view/${d.id}`);
              dispatch(setContractSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {/* {canAccess("delete_contract") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}>
              <i
                className="pi pi-trash text-dark"

              ></i>{" "}
              Delete
            </li>
          )} */}
          {canAccess("delete_contract") && (
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
                setDeleteModal(true);
              }}>
              <img src={ArchiveIcon} />
              {d?.base_status === "archived" ? "Unarchive" : "Archive"}
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "contracts" : "contract"} from the list?`}
      </span>
    );
  };

  const handleContractDelete = () => {
    dispatch(
      archiveContractAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getContractList(
            profile?.company,
            rows,
            page,
            search,
            "",
            "",
            "",
            "",
            baseStatus
          );
          setSelectedItems([])
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
        </p>
      </>
    );
  };

  const getContractList = (
    company,
    rows,
    page,
    search,
    contractId,
    maturityType,
    rentRiseType,
    depositType,
    baseStatus
  ) => {
    dispatch(
      contractListAction(
        company,
        rows,
        page,
        search,
        contractId,
        maturityType,
        rentRiseType,
        depositType,
        baseStatus,
        (res) => {
          setContractList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    order,
    contractId,
    maturityType,
    rentRiseType,
    depositType,
    baseStatus
  ) => {
    setIsSearch(true);
    getContractList(
      company,
      rows,
      page,
      search,
      order,
      contractId,
      maturityType,
      rentRiseType,
      depositType,
      baseStatus
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getContractList(
        profile?.company,
        rows,
        page,
        search,
        filters?.contract_id,
        filters?.maturity_type,
        filters?.rent_rise_type,
        filters?.deposit_type,
        baseStatus
      );
      navigate(`/real-estate/others/contract/list?page=${1}&rows=${10}`)
    }
  }, [profile?.company]);

  // const handleSort = (e) => {
  //   if (sortField?.order === "-") {
  //     setSortField({ field: e?.sortField, order: "" });
  //   } else {
  //     setSortField({ field: e?.sortField, order: "-" });
  //   }
  // };

  const onPageChange = (e) => {
    navigate(
      `/real-estate/others/contract/list?page=${e.page + 1}&rows=${e.rows}`
    );
    getContractList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.contract_id,
      filters?.maturity_type,
      filters?.rent_rise_type,
      filters?.deposit_type,
      baseStatus
    );
    setFirst(e.first);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getContractList(
      profile?.company,
      rows,
      1,
      search,
      filters?.contract_id,
      filters?.maturity_type,
      filters?.rent_rise_type,
      filters?.deposit_type,
      baseStatus
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getContractList(
      profile?.company,
      rows,
      1,
      search,
      filters?.contract_id,
      filters?.maturity_type,
      filters?.rent_rise_type,
      filters?.deposit_type,
      baseStatus
    );
    options.filterClearCallback("");
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  return (
    <div>
      <CustomCard title="Contract List" header={header()}>
        <CustomTableContainer
          list={contractList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={contract?.id}
          slice={setContractSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Contract ID"
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={idToDelete?.action === "archived" ? "Archive" : "Unarchive"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleContractDelete}
        loading={loading}
      />
    </div>
  );
};

export default ContractList;
