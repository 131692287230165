import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import { debounce } from "lodash";
import { CustomInput, CustomSearch, CustomSwitch } from "shared/AllInputs";
// import CustomButton from "shared/CustomButton";
// import CustomOverlay from "shared/CustomOverlay";
import CustomCard from "shared/CustomCard";
// import manageColICon from "assets/images/icons/manage-col-icon.png";
import { getServicesListAction, serviceActiveAction, serviceInactiveAction } from "store/actions/ServicesSetupActions";

const ServicesSetupList = () => {
    const openAction = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [servicesList, setServicesList] = useState([]);
    const globalFilters = ["service_name", "nick_name", "property", "service_type", "legal_reference"];
    const [filters, setFilters] = useState({
        service_name: { value: "" },
        nick_name: { value: "" },
        property: { value: "" },
        service_type: { value: "" },
        legal_reference: { value: "" },
    });
    const [first, setFirst] = useState(0);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = query.get("page") || 1;
    const rows = query.get("rows") || 10;
    const [count, setCount] = useState(0);
    const [selectedItems, setSelectedItems] = useState();
    const [isSearch, setIsSearch] = useState(false);
    // const { service } = useSelector((state) => state?.service);
    const [columns, setColumns] = useState([]);
    // const [deleteModal, setDeleteModal] = useState(false);
    // const [idToDelete, setIdToDelete] = useState("");
    // const [loading, setLoading] = useState(false);
    const { profile } = useSelector((state) => state.user)

    useMemo(() => {
        setFirst(page * rows - rows);
    }, []);

    const header = () => {
        return (
            <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
                <div className="w-12 md:w-8 mr-3">
                    <CustomSearch
                        name="search"
                        value={search}
                        onChange={({ value }) => {
                            setSearch(value);
                            setFirst(0);
                            debouncedOnChange.current(
                                profile?.company,
                                10,
                                1,
                                value,
                                filters?.name,
                                filters?.email
                            );
                        }}
                        isSearch={isSearch}
                        placeholder="Search Service"
                    />
                </div>
            </div>
        );
    };

    const actions = (d) => {
        const status = d?.tenant_service.status == "active" ? true : false
        return (
            <CustomSwitch
                value={status}
                onChange={({ value }) => handleServceActiveInActive(d, value)}
                col={10}
            />
        );
    };

    const handleServceActiveInActive = (item, value) => {
        const id = item?.tenant_service?.id || item?.id
        if (value) {
            dispatch(
                serviceActiveAction(
                    profile?.company,
                    id,
                    dispatch,
                    (res) => {
                        getServicesList(profile?.company, rows, page, search, "", "");
                    }
                )
            );
        } else {
            dispatch(
                serviceInactiveAction(
                    profile?.company,
                    id,
                    dispatch,
                    (res) => {
                        getServicesList(profile?.company, rows, page, search, "", "");
                    }
                )
            );
        }
    }

    const ActionTemplate = () => {
        return (
            <>
                Active/Inactive
            </>
        );
    };

    const columnOptionsInitial = useMemo(
        () => [
            {
                name: "Services Name",
                accessor: "service_name",
                value: true,
                frozen: true,
                filter: false,
                filterElement: (options) => NameFilter(options),
            },
            {
                name: "Services Type",
                accessor: "service_type",
                value: true,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
            {
                name: "Property/Room",
                accessor: "property",
                value: true,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
            {
                name: "Legal Reference",
                accessor: "legal_reference",
                value: true,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
            {
                name: "Nick Name",
                accessor: "nick_name",
                value: false,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
            {
                name: "Start Date",
                accessor: "start_date",
                value: false,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
            {
                name: "End Date",
                accessor: "end_date",
                value: false,
                filter: false,
                filterElement: (options) => EmailFilter(options),
            },
        ],
        [servicesList, filters]
    );

    const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

    const NameFilter = (options) => {
        return (
            <div>
                <CustomInput
                    col={12}
                    value={options.value}
                    name="name"
                    onChange={({ name, value }) => {
                        handleFilterChange({ name: name, value: value });
                        options.filterCallback(value);
                    }}
                    className="p-column-filter"
                    placeholder="Enter Name"
                    ignoreLabel
                />
            </div>
        );
    };

    const EmailFilter = (options) => {
        return (
            <div>
                <CustomInput
                    col={12}
                    value={options.value}
                    name="email"
                    onChange={({ name, value }) => {
                        handleFilterChange({ name: name, value: value });
                        options.filterCallback(value);
                    }}
                    className="p-column-filter"
                    ignoreLabel
                />
            </div>
        );
    };

    const handleFilterChange = ({ name, value }) => {
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const getServicesList = (company, rows, page, search, name, email) => {
        dispatch(
            getServicesListAction(
                company,
                rows,
                page,
                search,
                name,
                email,
                (res) => {
                    setServicesList(res?.options);
                    setCount(res?.count);
                    setIsSearch(false);
                    // navigate(`/services?page=${page}&rows=${rows}`);
                }
            )
        );
    };

    const handleSearch = (company, rows, page, search, name, email) => {
        setIsSearch(true);
        getServicesList(company, rows, page, search, name, email);
    };

    const debouncedOnChange = useRef(debounce(handleSearch, 1000));

    const onPageChange = (e) => {
        navigate(`/settings/services-setups/list?page=${e.page + 1}&rows=${e.rows}`);

        getServicesList(
            profile?.company,
            e.rows,
            e.page + 1,
            search,
            filters?.name,
            filters?.email
        );
        setFirst(e.first);
        // navigate(`/services?page=${e}&rows=${rows}`);

    };

    const applyFilters = (options) => {
        getServicesList(
            profile?.company,
            rows,
            1,
            "",
            filters?.name,
            filters?.email
        );
        options.filterApplyCallback();
    };

    const clearFilter = (options) => {
        const field = options.field;
        setFilters((prev) => ({ ...prev, [field]: "" }));
        getServicesList(
            profile?.company,
            rows,
            1,
            "",
            field === "name" ? "" : filters?.name,
            field === "email" ? "" : filters?.email
        );
        options.filterClearCallback();
    };

    useEffect(() => {
        if (profile?.company) {
            getServicesList(
                profile?.company,
                rows,
                page,
                "",
                filters?.name,
                filters?.email
            );
            navigate(`/settings/services-setups/list?page=${1}&rows=${10}`);
        }
    }, [profile]);

    useEffect(() => {
        let filterdCols = columnOptions.filter((col) => col.value === true);
        setColumns(filterdCols);
    }, []);


    return (
        <div>
            <CustomCard title="Service List" header={header()}>
                <CustomTableContainer
                    list={servicesList}
                    columns={columns}
                    actions={actions}
                    setColumns={setColumns}
                    first={first}
                    rows={rows}
                    count={count}
                    ActionTemplate={ActionTemplate}
                    // activeRow={service?.id}
                    // slice={setServiceSlice}
                    globalFilters={globalFilters}
                    handleSort={""}
                    onPageChange={onPageChange}
                    filters={filters}
                    setFilters={setFilters}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    columnOptions={columnOptions}
                    setColumnOptions={setColumnOptions}
                    openAction={openAction}
                    firstCol="Services Name"
                    notShowSelection
                />
            </CustomCard>
        </div>
    );
};

export default ServicesSetupList;
