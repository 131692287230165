import React, { useMemo } from "react";
import { CustomAmountViewInput, CustomNumberViewInput, CustomViewInput } from "shared/AllViewInputs";
import CommonViewTable from "views/Tables/CommonViewTable";
import { getTenantName } from "../externalRentFunctions";
import SizeM2 from "assets/images/sizem2.svg"

const MaintenancecostTable = ({ items }) => {
    const columns = useMemo(() => [
        {
            name: "No. of Tenants",
            accessor: "number_of_tenant",
            value: true,
            body: (options) => <CustomViewInput value={getTenantName(options.number_of_tenant)} />,
            style: { minWidth: "200px" }
        },
        {
            name: "Size",
            accessor: "size",
            value: true,
            body: (options) => <CustomNumberViewInput value={options.size} spanIcon={SizeM2} />,
            style: { minWidth: "200px" }
        },
        {
            name: "Avg. Maintenance Cost by sqm",
            accessor: "avg_cost",
            value: true,
            body: (options) => <CustomAmountViewInput value={options.avg_cost} />,
            style: { minWidth: "200px" }
        },
        {
            name: "Gross Maintenance Cost",
            accessor: "gross_cost",
            value: true,
            body: (options) => <CustomAmountViewInput value={options.gross_cost} />,
            style: { minWidth: "200px" }
        },
    ], [])

    return (
        <CommonViewTable
            title={"Maintenance Cost"}
            list={items}
            columns={columns}
        />
    )
}

export default MaintenancecostTable