import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { canAccess } from "services/auth";
import { BuildingTypeOptions, StairAccessOptions } from "shared/AllDropDownOptions";
// import { Dropdown } from "primereact/dropdown";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { debounce } from "lodash";
import {
  archiveBuildingAction,
  // deleteBuildingAction,
  getBuildingListAction,
} from "store/actions/buildingActions";
import { setBuildingSlice } from "store/slices/buildingSlices";
// import { Tooltip } from "primereact/tooltip";
// import { truncateText } from "utils/regex";
import ArchiveIcon from "assets/images/icons/archive.svg"
import { showToastAction } from "store/slices/commonSlice";

const BuildingList = () => {
  const openAction = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalFilters = ["name", "type", "address", "staircase"];
  const [filters, setFilters] = useState({
    name: "",
    type: "",
    address: "",
    staircase: "",
  });

  const [filters2, setFilters2] = useState({
    name: { value: "" },
    type: { value: "" },
    address: { value: "" },
    staircase: { value: "" },
  });

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);
  const [buildingList, setBuildingList] = useState([]);
  const { building } = useSelector((state) => state?.building);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState({ id: [], action: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  // const [sortField, setSortField] = useState({ field: "", order: "" });
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState();
  const [columns, setColumns] = useState([]);
  const [baseStatus, setBaseStatus] = useState("")

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                rows,
                1,
                value,
                filters?.name,
                filters?.type,
                filters?.address,
                filters?.staircase,
                baseStatus
              );
            }}
            isSearch={isSearch}
            placeholder="Search Building"
          />
        </div>
        {canAccess("add_building") && <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Building"
          onClick={() => navigate("/real-estate/others/building/add")}
        />}
        <CustomOverlay>
          <ul className="no-style p-0">
            {baseStatus === "archived" ? <><li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setBaseStatus("")
                setSelectedItems([])
                getBuildingList(
                  profile?.company,
                  10,
                  1,
                  "",
                  // order,
                  filters?.name,
                  filters?.type,
                  filters?.address,
                  filters?.staircase,
                  "active"
                );
              }}
            >
              <img src={ArchiveIcon} />
              Hide Archived
            </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "restore") }}
              >
                <img src={ArchiveIcon} />
                {`Unarchive (${selectedItems?.length || 0})`}
              </li>
            </> : <>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setBaseStatus("archived")
                  setSelectedItems([])
                  getBuildingList(profile?.company, 10, 1, "", filters?.name, filters?.type, filters?.address, filters?.staircase, "archived");
                }}
              >
                <img src={ArchiveIcon} />
                Show Archived
              </li>
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => { archiveUnarchiveMultiple(selectedItems, "archived") }}
              >
                <img src={ArchiveIcon} />
                {`Archive (${selectedItems?.length || 0})`}
              </li></>
            }
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const BuildingNameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="name"
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value })
            options.filterCallback(value);
          }}
          placeholder="Enter Building Name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const BuildingTypeFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          value={options.value}
          options={BuildingTypeOptions}
          // onChange={(e) => {
          //   options.filterCallback(e.value);
          // }}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value })
            options.filterCallback(value);
          }}
          col={12}
          optionLabel="name"
          name="type"
          placeholder="Select Building Type"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  // const AddressFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         value={options.value}
  //         name="address"
  //         onChange={(e) => {
  //           options.filterCallback(e.value);
  //         }}
  //         optionLabel="name"
  //         placeholder="Address"
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  // const addressBodyTemplate = (options) => {
  //   return (
  //     <div>
  //       <Tooltip
  //         className="tooltip"
  //         target={`.tooltip-${options?.id}`}
  //         content={options?.address}
  //       />
  //       <span
  //         className={`tooltip-${options?.id}`}
  //         data-pr-classname="tooltip"
  //         style={{ cursor: "pointer" }}
  //       >
  //         <span
  //           // style={{
  //           //   color: options?.id === building?.id ? "white" : null,
  //           // }}
  //         >
  //           {truncateText(options?.address, 20)}
  //         </span>
  //       </span>
  //     </div>
  //   );
  // };

  const StairCaseFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          value={options.value}
          options={StairAccessOptions}
          // onChange={(e) => {
          //   options.filterCallback(e.value);
          // }}
          onChange={({ name, value }) => {
            handleFilterChange({ name: name, value: value })
            options.filterCallback(value);
          }}
          col={12}
          optionLabel="name"
          name="staircase"
          placeholder="Select Straircase Access"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const getStairCaseName = (staircase) => {
    switch (staircase) {
      case "direct":
        return "Direct Access";

      case "common":
        return "Access to Common Floor";

      case "combined":
        return "Combined Access";

      default:
        return "";
    }
  }

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Building Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => BuildingNameFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   value: true,
      //   filter: true,
      //   body: (options) => addressBodyTemplate(options),
      //   filterElement: (options) => AddressFilter(options),
      // },
      {
        name: "Building Type",
        accessor: "type",
        value: true,
        filter: true,
        filterElement: (options) => BuildingTypeFilter(options),
      },
      {
        name: "Building Max Floor",
        accessor: "max_floor",
        sortable: false,
        value: true,
      },
      {
        name: "Staircase Access",
        accessor: "staircase",
        sortable: false,
        value: true,
        filter: true,
        body: (options) => getStairCaseName(options?.staircase),
        filterElement: (options) => StairCaseFilter(options),
      },
      {
        name: "Size",
        accessor: "size",
        sortable: false,
        value: true,
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "city_division", value: false },
      { name: "Country", accessor: "country", value: false },
    ],
    []
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/others/building/view/${d.id}`);
              dispatch(setBuildingSlice(d));
            }}
          >
            <i className="pi pi-eye text-dark"></i>
            View
          </li>
          {/* {canAccess("delete_building") && <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
            <i
              className="pi pi-trash text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>} */}
          {canAccess("delete_building") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete({ id: [d?.id], action: d?.base_status === "archived" ? "restore" : "archived" });
                setDeleteModal(true);
              }}
            >
              <img src={ArchiveIcon} />
              {d?.base_status === "archived" ? "Unarchive" : "Archive"}
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        {`Are you sure you want to ${idToDelete?.action === "archived" ? "archive" : "unarchive"} the selected ${idToDelete?.id?.length > 1 ? "buildings" : "building"} from the list?`}
      </span>
    );
  };

  const handleBuildingDelete = () => {
    dispatch(
      archiveBuildingAction(
        profile?.company,
        idToDelete?.id,
        idToDelete?.action,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getBuildingList(profile?.company, rows, page, search, "", "", "", "", baseStatus);
          setSelectedItems([])
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center cursor-pointer"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const getBuildingList = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address,
    staircase,
    baseStatus
  ) => {
    dispatch(
      getBuildingListAction(
        company,
        rows,
        page,
        search,
        // order,
        name,
        type,
        address,
        staircase,
        baseStatus,
        (res) => {
          setBuildingList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          // navigate(
          //   `/real-estate/others/building/list?page=${page}&rows=${rows}`
          // );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address,
    staircase,
    baseStatus,
  ) => {
    setIsSearch(true);
    getBuildingList(
      company,
      rows,
      page,
      search,
      // order,
      name,
      type,
      address,
      staircase,
      baseStatus
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getBuildingList(
        profile?.company,
        rows,
        page,
        search,
        filters?.name,
        filters?.type,
        filters?.address,
        filters?.staircase,
        baseStatus
      );
      navigate(
        `/real-estate/others/building/list?page=${1}&rows=${10}`
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // first,
    // filters?.name?.value,
    // filters?.type?.value,
    // filters?.address?.value,
    // filters?.staircase?.value
  ]);

  const onPageChange = (e) => {
    navigate(
      `/real-estate/others/building/list?page=${e.page + 1}&rows=${e.rows}`
    );
    getBuildingList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.name,
      filters?.type,
      filters?.address,
      filters?.staircase,
      baseStatus
    );
    setFirst(e.first);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  const applyFilters = (options) => {
    options.filterApplyCallback();
    getBuildingList(
      profile?.company,
      rows,
      1,
      "",
      filters?.name,
      filters?.type,
      filters?.address,
      filters?.staircase,
      baseStatus
    );
    setFirst(0)
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getBuildingList(
      profile?.company,
      rows,
      1,
      "",
      field === "name" ? "" : filters?.name,
      field === "type" ? "" : filters?.type,
      field === "address" ? "" : filters?.address,
      field === "staircase" ? "" : filters?.staircase,
      baseStatus
    );
    options.filterClearCallback("");
    setFirst(0)
  };

  const archiveUnarchiveMultiple = (items, action) => {
    if (items?.length) {
      const selectedIds = items?.map((item) => { return item?.id })
      setIdToDelete({ id: selectedIds, action: action });
      setDeleteModal(true);
    } else {
      dispatch(showToastAction({
        type: "error",
        detail: "Please select at least one item.",
      }))
    }
  }

  return (
    <div>
      <CustomCard title="Building List" header={header()}>
        <CustomTableContainer
          list={buildingList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={building?.id}
          slice={setBuildingSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters2}
          // setFilters={setFilters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Building Name"
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={idToDelete?.action === "archived" ? "Archive" : "Unarchive"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleBuildingDelete}
        loading={loading}
      />
    </div>
  );
};

export default BuildingList;
