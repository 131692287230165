import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomAutoComplete, CustomForm } from "shared/AllInputs";
import ExternalRentSettingsContainer from "./ExternalRentSettingsContainer";
import { TabPanel, TabView } from "primereact/tabview";
import { formatCharFieldName } from "utils/regex";
import Tier1 from "./Tiers/Tier1";
import Tier2 from "./Tiers/Tier2";
import Tier3 from "./Tiers/Tier3";
import Tier4 from "./Tiers/Tier4";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";

const ExternalRentSettingsFrom = () => {

    const {
        data,
        handleChange,
        activeIndex,
        setActiveIndex,
        regulationOptions,
        regulationChange,
        onSubmit,
        loading,
        tierData1,
        setTierData1,
        tierData2,
        setTierData2,
        tierData3,
        setTierData3,
        tierData4,
        setTierData4,
        servicesList,
        setServicesList,
        tiersArray,
        disableTabs,
        getExternalRentSetup
    } = ExternalRentSettingsContainer()

    return (
        <Page
            title={"Settings"}
        >
            <CustomCard title="External Rent Settings">
                <div className="page-title ">Here you can manage your external rent settings based on a three tier Managements</div>
                <CustomForm>
                    <CustomAutoComplete
                        field="name"
                        data={data}
                        onChange={handleChange}
                        name="regulation"
                        suggestions={regulationOptions || []}
                        editable
                        extraClassName="px-0"
                        panelClassName="dropdown-option-color"
                        label="Search Regulation"
                        placeholder="Search Regulation"
                        search={(e) => regulationChange.current(e.query)}
                        required
                    />
                </CustomForm>
                {tiersArray?.length ? <TabView
                    className="tabs-design"
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                        setActiveIndex(e?.index);
                    }}
                >
                    {Object?.entries(data?.tiers).sort(([keyA], [keyB]) => {
                        // Extract the numeric part from the "tier" strings and compare them numerically
                        const tierNumberA = parseInt(keyA.replace('tier', ''), 10);
                        const tierNumberB = parseInt(keyB.replace('tier', ''), 10);
                        return tierNumberA - tierNumberB; // Sort in ascending order (tier1, tier2, tier3, tier4)
                    }).map(([key, value], index) => {
                        return (
                            <TabPanel header={formatCharFieldName(key)} key={key} disabled={disableTabs.includes(index)}>
                                {key === "tier1" && <Tier1 data={value} tierData={tierData1} setTierData={setTierData1} servicesList={servicesList} setServicesList={setServicesList} />}
                                {key === "tier2" && <Tier2 data={value} tierData={tierData2} setTierData={setTierData2} />}
                                {key === "tier3" && <Tier3 data={value} tierData={tierData3} setTierData={setTierData3} />}
                                {key === "tier4" && <Tier4 data={value} tierData={tierData4} setTierData={setTierData4} />}
                            </TabPanel>
                        )
                    })}
                </TabView> : null}
            </CustomCard>
           
            {tiersArray?.length ? <Buttonlayout>
                {activeIndex > 0 && <CustomButtonOutlined label="Back" onClick={() => setActiveIndex(activeIndex - 1)} />}
                <CustomButton
                    onClick={onSubmit}
                    label={
                        tiersArray?.length - 1 == activeIndex ?
                            tiersArray[activeIndex]?.id ?
                                "Update" : "Save" :
                            tiersArray[activeIndex]?.id ?
                                "Update & Next" : "Next"}
                    loading={loading}
                />
                <CustomButtonOutlined label="Cancel" onClick={() => getExternalRentSetup()} />
            </Buttonlayout> : null}
        </Page>
    )
}

export default ExternalRentSettingsFrom