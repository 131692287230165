import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { canAccess } from "services/auth";

const RolesListTable = ({
  role,
  setIdToDelete,
  setDeleteModal,
  setSelectedRole,
  setIsOpen,
}) => {
  const navigate = useNavigate();
  const headerOp = useRef(null);

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  return (
    <>
      <Accordion activeIndex={role.id}>
        <AccordionTab
          headerClassName="accordion-role-list"
          header={
            <div className="flex align-items-center justify-content-between w-full ">
              <p>{role?.name}</p>
              <i
                className="pi pi-ellipsis-v cursor-pointer text-dark text-xl"
                onClick={(e) => headerOp.current.toggle(e)}
              ></i>
              <OverlayPanel ref={headerOp} className="px-3">
                <ul className="no-style p-0">
                  <li
                    className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                    onClick={() =>
                      navigate(`/administrator/roles/view/${role?.id}`)
                    }
                  >
                    <i className="pi pi-eye text-dark"></i> View
                  </li>
                  {canAccess("change_employee") && (
                    <li
                      className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                      onClick={() => {
                        setSelectedRole(role);
                        setIsOpen(true);
                      }}
                    >
                      <i className="pi pi-user-plus text-dark"></i> Add Employee
                    </li>
                  )}
                  {canAccess("delete_companyrole") && (
                    <li
                      className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                      onClick={() => {
                        setIdToDelete(role?.id);
                        setDeleteModal(true);
                      }}
                    >
                      <i className="pi pi-trash text-dark"></i> Delete
                    </li>
                  )}
                </ul>
              </OverlayPanel>
            </div>
          }
        >
          <DataTable value={role?.employees} tableStyle={{ minWidth: "50rem" }}>
            <Column
              className="firstletter_capital"
              headerClassName="custom-header"
              field="name"
              header="Name"
            ></Column>
            <Column
              className="firstletter_capital"
              headerClassName="custom-header"
              field="email"
              header="Email"
            ></Column>
            <Column
              className="firstletter_capital"
              headerClassName="custom-header"
              field="role"
              header="Role"
            ></Column>
            {/* <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column> */}
          </DataTable>
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default RolesListTable;
