import React from "react";
import BaserentTable from "./Tables/BaserentTable";
import MaintenancecostTable from "./Tables/MaintenancecostTable";
import HeatingCostTable from "./Tables/HeatingcostTable";
import HeatingDeductionTable from "./Tables/HeatingDeductionTable";
import { CustomAmountViewInput } from "shared/AllViewInputs";
import { CustomForm } from "shared/AllInputs";
import AreaSelectionForm from "./AreaSelectionForm";

const ExternalRentTableForm = ({ data, tierData, handleChange }) => {
    const shouldShowAreaSelectionForm = ['base_rent', 'maintenance_rent', 'heating_rent', 'heating_deduction'].some(key => key in data);
    return (
        <>
            {
                Object.entries(data).map(([key, value]) => {

                    if (key === "base_rent") {
                        return (
                            <BaserentTable items={value?.costs} />
                        )
                    }

                    if (key === "maintenance_rent") {
                        return (
                            <MaintenancecostTable items={value?.costs} />
                        )
                    }

                    if (key === "heating_rent") {
                        return (
                            <HeatingCostTable items={value?.costs} />
                        )
                    }

                    if (key === "heating_deduction") {
                        return (
                            <HeatingDeductionTable items={value?.deductions} />
                        )
                    }

                    if (key === "admin_cost") {
                        return (
                            <CustomForm title={"Administrative Cost"}>
                                <CustomAmountViewInput extraClassName="px-0" name="administrativeCost" value={value?.cost} />
                            </CustomForm>
                        )
                    }
                })
            }

            {shouldShowAreaSelectionForm && <AreaSelectionForm data={tierData} handleChange={handleChange}/>}
        </>
    )
}

export default ExternalRentTableForm