import React from "react";
import { CustomForm, CustomRadioInput } from "shared/AllInputs";

const OverRideControlForm = ({ data, handleChange }) => {

    return (
        <CustomForm title={"Override Control"} formClass={"py-2"}>
            <p>Please indicate whether you wish to deactivate the rent review tier. While this typically results in rejected rental agreements, it may prove beneficial if the review regulation is outdated.</p>
            <CustomRadioInput
                data={data}
                onChange={handleChange}
                name="overRideControl"
                htmlFor="Active"
                extraClassName="px-0"
                value="Active"
                label={"Active"}
                checked={data?.overRideControl === "Active"}
            />
            <CustomRadioInput
                data={data}
                onChange={handleChange}
                name="overRideControl"
                value="Inactive"
                label="Inactive"
                htmlFor="Inactive"
                checked={data?.overRideControl === "Inactive"}
            />
        </CustomForm>
    )
}

export default OverRideControlForm