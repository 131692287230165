import React from "react";
import { CustomForm } from "shared/AllInputs";
import {
  CustomNumberViewInput,
  // CustomImageView,
  CustomViewInput,
  NewCustomImageView,
} from "shared/AllViewInputs";
import EditIcon from "assets/images/icons/editicon.png";
import { useNavigate, useParams } from "react-router-dom";
import { canAccess } from "services/auth";
import SizeM2 from "assets/images/svg/SizeM2.svg";

const ViewBuildingStructure = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(
              `/real-estate/property/edit/building-structure/${params?.id}`
            )
          }
        ></img>
      </div>
    );
  };
  return (
    <>
      <CustomForm
        title="Building Structure"
        header={canAccess("change_property") && header()}
      >
        <CustomViewInput name="buildingName" data={data} label={"Building"} />
        <CustomViewInput name="buildingType" data={data} />
        <CustomViewInput name="buildingMaxFloor" data={data} />
        <CustomViewInput name="staircaseAccess" data={data} />
        {/* <CustomViewInput name="size" data={data} label={"Size Sqm"} /> */}
        <CustomNumberViewInput
          name="size"
          data={data}
          spanIcon={SizeM2}
          // spanLabel={
          //   <span>
          //     m<sup>2</sup>
          //   </span>
          // }
        />
        {/* <div className="flex gap-4 flex-wrap w-full">
        {data?.document?.map((image, index) => {
          return <CustomImageView image={image.document} />;
        })}
      </div> */}
      </CustomForm>
      <br/>
      <CustomForm title={"Documents"}>
        {/* <div className="flex gap-4 flex-wrap w-full"> */}
          <NewCustomImageView
            files={data?.document}
            documentLabel={"building_energy_certificate"}
          />
        {/* </div> */}
      </CustomForm>
    </>
  );
};

export default React.memo(ViewBuildingStructure);
