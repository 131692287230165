import React, { useEffect, useMemo } from "react";
import { CustomAmountInput, CustomCheckbox, CustomForm } from "shared/AllInputs";
import CommonViewTable from "views/Tables/CommonViewTable";
import ExternalRentTableForm from "../ExternalRentTableForm";
import OverRideControlForm from "../OverRideControlForm";
import formValidation from "utils/validations";

const Tier1 = ({ data, tierData, setTierData, servicesList, setServicesList }) => {

    useEffect(() => {
        if (data) {
            let baseRent = ""
            let maintenance = ""
            if (data?.tier_deduction_control) {
                data?.tier_deduction_control?.map((item) => {
                    if (item?.type === "base_rent") {
                        baseRent = item?.value
                    } else if (item?.type === "maintenance") {
                        maintenance = item?.value
                    }
                })
            }

            setTierData({
                data: data?.data || data,
                id: tierData?.id || data?.id ||  null,
                deductionControl: tierData?.deductionControl || data?.deduction_control ||  null,
                baseRent: tierData?.baseRent || baseRent || null,
                maintenance: tierData?.maintenance || maintenance ||  null,
                overRideControl: tierData?.overRideControl == undefined ?  data?.override_control : tierData?.overRideControl ? "Active" : "Inactive",
                areaSelection: tierData?.areaSelection || data?.area_selection ||  "abstractArea",
            })
        }
    }, [data])

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, tierData)
        setTierData((prev) => ({ ...prev, [name]: value, formErrors }))
    }

    const handleRowChange = ({ name, value, id }) => {
        const allValues = [...servicesList]
        const formErrors = formValidation(name, value, allValues, [], ["maximumOverride"])
        const indexToUpdate = allValues.findIndex((item) => item.id === id)
        allValues[indexToUpdate][name] = value
        allValues[indexToUpdate].formErrors = formErrors
        setServicesList(allValues)
    }

    const NumberEditor = ({ name, options, inputIcon, state }) => {
        return (
            <CustomAmountInput
                extraClassName="w-full p-0"
                inputClass="m-0"
                data={options}
                col={12}
                onChange={({ value }) => {
                    handleRowChange({ name: name, value: value, id: options?.id })
                }}
                name={name}
                spanIcon={inputIcon}
                spanExtraClassName={"p-2"}
                ignoreLabel
            />
        );
    }

    const columns = useMemo(() => [
        {
            name: "Service Name",
            accessor: "service_name"
        },
        {
            name: "Enter Maximum override",
            accessor: "maximumOverride",
            body: (options) => NumberEditor({ name: "maximumOverride", options: options, state: servicesList }),
        },
    ], [servicesList])

    return (
        <>
            <ExternalRentTableForm data={data?.data || data} tierData={tierData} handleChange={handleChange} />
            <CustomForm title={"Modifier special cases"} formClass={"py-2"}>
                <p>Please choose the maximum applicable override category for each support service.</p>
                <CommonViewTable
                    list={servicesList}
                    columns={columns}
                />
            </CustomForm>
            <CustomForm title={"Deduction Control"} formClass={"py-2"}>
                <p>If over government maximum rent: Choose deduction option</p>
                <CustomCheckbox
                    label={"Based On Incoming contract"}
                    name="deductionControl"
                    onChange={handleChange}
                    col={12}
                    extraClassName="px-0"
                    data={tierData}
                />
                <CustomAmountInput
                    name="baseRent"
                    data={tierData}
                    extraClassName="md:pl-0"
                    onChange={handleChange}
                    spanLabel={<i className="pi pi-percentage"></i>}
                    required
                />
                <CustomAmountInput
                    name="maintenance"
                    data={tierData}
                    onChange={handleChange}
                    spanLabel={<i className="pi pi-percentage"></i>}
                    required
                />
            </CustomForm>
            <OverRideControlForm handleChange={handleChange} data={tierData} />
        </>
    )
}

export default Tier1