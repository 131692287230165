import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import TenantContainer from "./TenantContainer";
import { TabPanel, TabView } from "primereact/tabview";
import SocialOffice from "./SocialOffice";
import AddFamily from "views/CommonComponents/AddFamily";
import Tenant from "./Tenant";
import TenantContractForm from "./TenantContractForm";
import { useNavigate } from "react-router-dom";

export default function TenantForm({ type }) {
  const {
    data,
    setData,
    handleChange,
    disableTabs,
    handleTabChange,
    activeIndex,
    onSubmit,
    allFamilies,
    setAllFamilies,
    loading,
    tenantId,
  } = TenantContainer();
  const navigate = useNavigate()
  return (
    <Page title={`${type} Tenant`} description={`Let’s ${type} your Tenant`}>
      <CustomCard>
        <Tenant data={data} handleChange={handleChange} isDisabled={activeIndex != 0} />
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Social Office">
            <SocialOffice data={data} setData={setData} handleChange={handleChange} />
          </TabPanel>
          <TabPanel header="Family" disabled={type !== "Edit" && disableTabs.includes(1)}>
            <AddFamily allValues={allFamilies} setAllValues={setAllFamilies} tenantId={tenantId} />
          </TabPanel>
          <TabPanel header="Contracts" disabled={type !== "Edit" && disableTabs.includes(2)}>
            <TenantContractForm />
          </TabPanel>
        </TabView>
      </CustomCard>
      <Buttonlayout>
        {activeIndex !== 2 ? <>
          <CustomButton onClick={onSubmit} label="Save & Next" loading={loading} />
          <CustomButtonOutlined label="Cancel" onClick={() => navigate(-1)} />
        </>
          :
          <>
            <CustomButton onClick={() => navigate("/tenants/rent-calculation")} label="View Rent Detail" loading={loading} />
            <CustomButtonOutlined label="Skip to Offer" onClick={() => navigate(-1)} />
            <CustomButtonOutlined label="Cancel" onClick={() => navigate(-1)} />
          </>}
      </Buttonlayout>
    </Page>
  );
}
