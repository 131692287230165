import { ReactComponent as Home } from "assets/images/svg/Home.svg";
import { ReactComponent as HomeBlue } from "assets/images/svg/Home-primary.svg";
import { ReactComponent as Overview } from "assets/images/svg/Overview.svg";
import { ReactComponent as OverviewBlue } from "assets/images/svg/Overview-primary.svg";
import { ReactComponent as Tenant } from "assets/images/svg/Tenant.svg";
import { ReactComponent as TenantBlue } from "assets/images/svg/Tenant-primary.svg";
import { ReactComponent as Administrator } from "assets/images/svg/Administrator.svg";
import { ReactComponent as AdministratorBlue } from "assets/images/svg/Administrator-primary.svg";
import { ReactComponent as Reports } from "assets/images/svg/Reports.svg";
import { ReactComponent as Contract } from "assets/images/svg/Contract.svg";
// import { ReactComponent as Settings } from "assets/images/svg/Settings.svg";
export const sidebarItems = [
  {
    title: "Overview",
    link: "/dashboard",
    icon: Overview,
    activeIcon: OverviewBlue,
  },
  {
    title: "Real Estate",
    link: "/real-estate",
    icon: Home,
    activeIcon: HomeBlue,
    codenames: ["add_contract", "view_contract", "view_property", "add_property", "view_building", "add_building", "view_landlord", "add_landlord", "view_damagereport", "add_damagereport", "view_annualstatement", "add_annualstatement"],
    items: [
      { title: "Properties", link: "/property/list", codename: "view_property" },
      {
        title: "Maintenance",
        link: "/maintenance",
        codenames: ["view_damage", "view_damagereport", "view_handyman"],
        items: [
          {
            title: "Maintenance",
            link: "/list",
            codename: "view_damage"
          },
          {
            title: "Damages",
            link: "/damage/list",
            codename: "view_damagereport"
          },
          {
            title: "Handyman",
            link: "/handyman/list",
            codename: "view_handyman"
          },
        ],
      },
      { title: "Annual Statement", link: "/annualstatement/list", codename: "view_annualstatement" },
      {
        title: "Others",
        codenames: ["view_landlord", "view_contract", "view_building"],
        items: [
          {
            title: "Landlords",
            link: "/others/landlord/list",
            codename: "view_landlord"
          },
          {
            title: "Contracts",
            link: "/others/contract/list",
            codename: "view_contract"
          },
          {
            title: "Buildings",
            link: "/others/building/list",
            codename: "view_building"
          },
        ],
      },
    ],
    // items: [
    //   {
    //     title: "Properties",
    //     link: "/property",
    //     items: [
    //       {
    //         title: "Property List",
    //         link: "/list",
    //         codename: "view_property"
    //       },
    //       {
    //         title: "Add Property",
    //         link: "/add/building-structure",
    //         codename: "add_property"
    //       },
    //       {
    //         title: "Contract List",
    //         link: "/contract/list",
    //         codename: "view_contract"
    //       },
    //       {
    //         title: "Add Contract",
    //         link: "/contract/add",
    //         codename: "add_contract"
    //       },
    //       {
    //         title: "Building List",
    //         link: "/building/list",
    //         codename: "view_building"
    //       },
    //       {
    //         title: "Add Building",
    //         link: "/building/add",
    //         codename: "add_building"
    //       },
    //     ],
    //   },
    //   {
    //     title: "Landlord",
    //     link: "/landlord",
    //     items: [
    //       {
    //         title: "Landlord List",
    //         link: "/list",
    //         codename: "view_landlord"
    //       },
    //       {
    //         title: "Add Landlord",
    //         link: "/add",
    //         codename: "add_landlord"
    //       },
    //     ],
    //   },
    //   {
    //     title: "Maintenance",
    //     link: "/maintenance",
    //     items: [
    //       {
    //         title: "Maintenance Req.",
    //         link: "/list",
    //         codename: "view_damage"
    //       },
    //       {
    //         title: "Damage List",
    //         link: "/damage/list",
    //         codename: "view_damagereport"
    //       },
    //       {
    //         title: "Add Damage",
    //         link: "/damage-report/add",
    //         codename: "add_damagereport"
    //       },
    //       {
    //         title: "Handyman List",
    //         link: "/handyman/list",
    //         codename: "view_handyman"
    //       },
    //       {
    //         title: "Handyman Add",
    //         link: "/handyman/add",
    //         codename: "add_handyman"
    //       },
    //     ],
    //   },
    //   {
    //     title: "Annual Statement",
    //     link: "/annualstatement",
    //     items: [
    //       {
    //         title: "List",
    //         link: "/list",
    //         codename: "view_annualstatement"
    //       },
    //       {
    //         title: "Add",
    //         link: "/add",
    //         codename: "add_annualstatement"
    //       },
    //     ],
    //   },
    // ],
  },
  {
    title: "Tenants",
    link: "/tenants",
    icon: Tenant,
    activeIcon: TenantBlue,
    codenames: ["view_tenant", "add_tenant"],
    items: [
      { title: "Tenants List", link: "/list", codename: "view_tenant" },
      // { title: "Add Tenants", link: "/add", codename: "add_tenant" },
    ],
  },

  {
    title: "Administrator",
    link: "",
    icon: Administrator,
    activeIcon: AdministratorBlue,
    codenames: ["view_officeunit", "view_office", "view_employee", "view_companyrole"],
    items: [
      { title: "Office Unit", link: "/administrator/office-unit/list", codename: "view_officeunit" },
      { title: "Office", link: "/administrator/office/list", codename: "view_office" },
      { title: "Employees", link: "/administrator/employees/list", codename: "view_employee" },
      { title: "Roles (Profiles)", link: "/administrator/roles/list", codename: "view_companyrole" },
      { 
        title: "Settings", 
        link: "/settings", 
        codenames: ["view_services", "default"],
        items: [
          { title: "Internal Rent Settings", link: "/internal-rent-settings", codename: "default" },
          { title: "External Rent Settings", link: "/external-rent-settings", codename: "default" },
          { title: "Plans", link: "", codename: "" },
          { title: "Services Setup", link: "", codename: "" },
          { title: "Handyman Services", link: "/handyman-services", codename: "view_services" },
          { title: "Services Setups", link: "/services-setups/list", codename: "view_services" },
        ]
       },
    ],
  },

  // {
  //   title: "Administrator",
  //   link: "/administrator",
  //   icon: Administrator,
  //   codenames: ["view_officeunit", "view_office", "view_employee", "view_companyrole"],
  //   items: [
  //     {
  //       title: "Office Unit",
  //       link: "/office-unit/list",
  //       codename: "view_officeunit"
  //       // items: [
  //       //   {
  //       //     title: "Office Unit List",
  //       //     link: "/list",
  //       //     codename: "view_officeunit"
  //       //   },
  //       //   // {
  //       //   //   title: "Add Office Unit",
  //       //   //   link: "/add",
  //       //   //   codename: "add_officeunit"
  //       //   // },
  //       // ],
  //     },
  //     {
  //       title: "Office",
  //       link: "/office/list",
  //       codename: "view_office"
  //       // items: [
  //       //   {
  //       //     title: "Office List",
  //       //     link: "/list",
  //       //     codename: "view_office"
  //       //   },
  //       //   // {
  //       //   //   title: "Add Office",
  //       //   //   link: "/add",
  //       //   //   codename: "add_office"
  //       //   // },
  //       // ],
  //     },
  //     {
  //       title: "Employees",
  //       link: "/employees/list",
  //       codename: "view_employee"
  //       // items: [
  //       //   {
  //       //     title: "Employees List",
  //       //     link: "/list",
  //       //     codename: "view_employee"
  //       //   },
  //       //   // {
  //       //   //   title: "Add Employees",
  //       //   //   link: "/add",
  //       //   //   codename: "add_employee"
  //       //   // },
  //       // ],
  //     },
  //     {
  //       title: "Roles (Profiles)",
  //       link: "/roles/list",
  //       codename: "view_companyrole"
  //       // items: [
  //       //   {
  //       //     title: "Roles",
  //       //     link: "/list",
  //       //     codename: "view_companyrole"
  //       //   },
  //       //   // {
  //       //   //   title: "Add Roles",
  //       //   //   link: "/add",
  //       //   //   codename: "add_companyrole"
  //       //   // },
  //       // ],
  //     },
  //     {
  //       title: "Settings",
  //       link: "/roles/list",
  //       codenames: ["view_companyrole"],
  //       items: [
  //         {
  //           title: "Internal Rent Settings",
  //           link: "/",
  //           codename: ""
  //         },
  //         {
  //           title: "External Rent Settings",
  //           link: "/",
  //           codename: ""
  //         },
  //         {
  //           title: "Plans",
  //           link: "/",
  //           codename: ""
  //         },
  //         {
  //           title: "Services Setup",
  //           link: "/",
  //           codename: ""
  //         },
  //         {
  //           title: "Handyman Service",
  //           link: "/handyman-services",
  //           codename: "view_services"
  //         },
  //       ],
  //     },
  //   ]
  // },
  {
    title: "Reports",
    link: "/reports",
    codename: "",
    icon: Reports,
    items: [
      { title: "Energy Reports", link: "/", codename: "" },
      { title: "Statement Reports", link: "/", codename: "" },
      { title: "Vacancy Reports", link: "/", codename: "" },
      { title: "Protocol Reports", link: "/", codename: "" },
      { title: "Custom", link: "/", codename: "" },
    ]
  },
  {
    title: "Contract Template",
    link: "/contract",
    codename: "",
    icon: Contract,
  },
  // {
  //   title: "Settings",
  //   link: "/settings",
  //   codenames: ["view_services"],
  //   icon: Settings,
  //   items: [
  //     {
  //       title: "Handyman Service",
  //       link: "/handyman-services",
  //       codename: "view_services"
  //     },
  //   ]
  // },
];
