import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { handleFileConversion, handleFormatDate } from "../../utils/commonFunctions";
import { showToastAction } from "../slices/commonSlice";
import { setContractSlice } from "../slices/contractSlice";
import { setPropertyContractSlice } from "../slices/propertySlice";
import { addFurnitureAction } from "./landlordActions";

const addContractAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    //contract
    let movingDate = handleFormatDate(data?.movingDate);
    let maturityDate = handleFormatDate(data?.maturityDate);
    let payload = {
        contract_id: data?.existingContract || data?.contractId,
        // estate_company: data?.estateCompany,
        size: data?.contractualSize,
        room: data?.contractualNo,
        landlord: data?.landlordId,
        property: data?.property?.id || data?.property,
    };

    const docs = []
    for (const document of data?.contract_documents || []) {
        for (const [documentType, documentArray] of Object.entries(document)) {
            if (documentArray?.length > 0) {
                const documents = await Promise.all(documentArray.map(async (document) => {
                    if (typeof document.document == "object") {
                        return { type: document?.type, document: await handleFileConversion(document?.document) };
                    } else {
                        return document
                    }
                }));
                docs.push(...documents);
            }
        }
    }

    if (docs.length) {
        payload.contract_documents = docs
    }

    if (movingDate) {
        payload.moving_in_date = movingDate
    }

    if (data?.maturityType) {
        payload.maturity_type = data?.maturityType

        if (data?.maturityType === "Automatic Renewal") {
            payload.maturity_date = maturityDate;
            payload.maturity_extension = data?.maturityExtension;
            payload.maturity_notice_period = data?.noticePeriod;
        } else if (data?.maturityType === "Optional Renewal") {
            payload.maturity_date = maturityDate;
        }
    }

    if (data?.depositType) {
        payload.deposit_type = data?.depositType
        if (data?.depositType !== "NONE" && data.amount) {
            payload.deposit_amount = data?.amount;
        }
    }

    if (data?.optional_maturities?.length) {
        payload.optional_maturities = data?.optional_maturities;
    }

    //rent and cost
    if (data?.amount) {
        payload.rent_amount = data?.amount
    }

    if (data?.amountFirstMonth) {
        payload.rent_amount_first_month = data?.amountFirstMonth
    }

    if (data?.rentRiseType) {
        payload.rent_rise_type = data?.rentRiseType

        if (data?.rentRiseType !== "None") {
            payload.rent_rise_interval = data?.riseInterval;
            payload.rent_rise_interval_type = data?.intervalType;
            payload.date_of_first_rise = handleFormatDate(data?.startDate);
            payload.date_of_last_rise = handleFormatDate(data?.endDate);
            payload.rent_rise_amount = data?.riseAmount;
        }

    }

    if (data?.extra_costs?.length) {
        let extraCost = data?.extra_costs?.map((cost) => {
            if (cost?.applicable_tenant_rent === "yes") {
                return { ...cost, applicable_tenant_rent: true };
            } else if (cost?.applicable_tenant_rent === "no") {
                return { ...cost, applicable_tenant_rent: false };
            }
        });
        payload.extra_costs = extraCost;
    }

    if (data?.furnitureProvided) {
        payload.is_furniture = data?.furnitureProvided
    }

    // if (data?.landlord_furniture?.length) {
    //     payload.landlord_furniture = data?.landlord_furniture;
    // }

    if (data?.bank_accounts?.length) {
        payload.bank_accounts = data?.bank_accounts;
    }

    const res = await api(
        "post",
        endPoints?.CONTRACT + `${company}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))

        if (data?.furnitureProvided && data?.landlord_furniture?.length) {
            // landlord_furniture = data?.landlord_furniture;
            dispatch(addFurnitureAction(data?.landlord_furniture, dispatch))
        }
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const searchContractAction = (company, id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT + `${company}/?contract_id=${id}&base_status=active`,
    )
    if(res?.success){
        const list = res?.data?.results
        const options = [{ name: `${"Add New: " + id}`, contract_id: id }]
        list?.map((item) => {
            options.push({ ...item, name: `${item?.contract_id}` })
        })
        onRes(options)
    }else{
        const options = [{name: `Add New: ${id}`, contract_id: id }]
        onRes(options)
    }
}

const contractSuggestions = (id, setContractOptions, onError) => async () => {
    let payload = {
        contract_id: id
    }
    const res = await api(
        "post",
        endPoints?.CONTRACT_SUGGESTIONS,
        payload
    )
    if (res.success) {
        setContractOptions([])
    } else {
        setContractOptions(res?.data?.results)
        onError(`${res?.message}!`)
    }
}

const updateContractAction = (company, contract, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    //contract
    let movingDate = handleFormatDate(data?.movingDate);
    let maturityDate = handleFormatDate(data?.maturityDate);
    let payload = {
        contract_id: data?.contractId || data?.existingContract,
        // estate_company: data?.estateCompany,
        size: data?.contractualSize,
        room: data?.contractualNo,
        landlord: data?.landlordId,
        property: data?.property?.id || data?.property,
    };

    if (movingDate) {
        payload.moving_in_date = movingDate
    }

    if (data?.maturityType) {
        payload.maturity_type = data?.maturityType
        if (data?.maturityType === "Automatic Renewal") {
            payload.maturity_date = maturityDate;
            payload.maturity_extension = data?.maturityExtension;
            payload.maturity_notice_period = data?.noticePeriod;
        } else if (data?.maturityType === "Optional Renewal") {
            payload.maturity_date = maturityDate;
        }
    }

    if (data?.optional_maturities?.length) {
        payload.optional_maturities = data?.optional_maturities;
    }


    if (data?.depositType) {
        payload.deposit_type = data?.depositType
        if (data?.depositType !== "NONE" && data.amount) {
            payload.deposit_amount = data?.amount;
        }
    }

    //rent and cost
    if (data?.amount) {
        payload.rent_amount = data?.amount
    }

    if (data?.amountFirstMonth) {
        payload.rent_amount_first_month = data?.amountFirstMonth
    }

    if (data?.rentRiseType) {
        payload.rent_rise_type = data?.rentRiseType

        if (data?.rentRiseType !== "None") {
            payload.rent_rise_interval = data?.riseInterval;
            payload.rent_rise_interval_type = data?.intervalType;
            payload.date_of_first_rise = handleFormatDate(data?.startDate);
            payload.date_of_last_rise = handleFormatDate(data?.endDate);
            payload.rent_rise_amount = data?.riseAmount;
        }

    }

    if (data?.extra_costs?.length) {
        let extraCost = data?.extra_costs?.map((cost) => {
            if (cost?.applicable_tenant_rent === "yes") {
                return { ...cost, applicable_tenant_rent: true };
            } else if (cost?.applicable_tenant_rent === "no") {
                return { ...cost, applicable_tenant_rent: false };
            }
        });
        payload.extra_costs = extraCost;
    }

    if (data?.furnitureProvided) {
        payload.is_furniture = data?.furnitureProvided
    }

    if (data?.furnitureProvided && data?.landlord_furniture?.length) {
        payload.landlord_furniture = data?.landlord_furniture;
        // dispatch(addFurnitureAction(data?.landlord_furniture, dispatch))
    }

    if (data?.bank_accounts?.length) {
        payload.bank_accounts = data?.bank_accounts;
    }

    const docs = []
    for (const document of data?.contract_documents || []) {
        for (const [documentType, documentArray] of Object.entries(document)) {
            if (documentArray?.length > 0) {
                const documents = await Promise.all(documentArray.map(async (document) => {
                    if (typeof document.document == "object") {
                        return { type: document?.type, document: await handleFileConversion(document?.document) };
                    } else {
                        return document
                    }
                }));
                docs.push(...documents);
            }
        }
    }

    if (docs.length) {
        payload.contract_documents = docs
    }

    const res = await api(
        "put",
        endPoints?.CONTRACT + `${company}/${contract}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))

        // if (data?.furnitureProvided && data?.landlord_furniture?.length) {
        //     // landlord_furniture = data?.landlord_furniture;
        //     dispatch(addFurnitureAction(data?.landlord_furniture, dispatch))
        // }
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const addRoomAndCostAction = (company, contract, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let startDate = handleFormatDate(data?.startDate);
    let endDate = handleFormatDate(data?.endDate);

    let payload = {
        rent_amount: data?.amount,
        rent_amount_first_month: data?.amountFirstMonth,
        rent_rise_type: data?.rentRiseType,
        property_rent: data?.propertyRent,
        landlord: data?.landlord,
    };

    if (data?.rentRiseType !== "None") {
        payload.rent_rise_interval = data?.riseInterval;
        payload.rent_rise_interval_type = data?.intervalType;
        payload.date_of_first_rise = startDate;
        payload.date_of_last_rise = endDate;
        payload.rent_rise_amount = data?.riseAmount;
    }
    // let extraCost = allExtraCost?.map((cost) => {
    //   if (cost?.applicable_tenant_rent === "yes") {
    //     return { ...cost, applicable_tenant_rent: true };
    //   } else if (cost?.applicable_tenant_rent === "no") {
    //     return { ...cost, applicable_tenant_rent: false };
    //   }
    // });

    if (data?.extra_costs?.length) {
        let extraCost = data?.extra_costs?.map((cost) => {
            let extraCostInner = {
                amount: cost?.amount,
                contract: cost?.contract,
                id: cost?.id,
                payment_type: cost?.payment_type,
                type: cost?.type,
            };

            if (cost?.applicable_tenant_rent === "yes") {
                extraCostInner.applicable_tenant_rent = true;

                if (cost?.amount_first_month) {
                    extraCostInner.amount_first_month = cost?.amount_first_month;
                }

                if (cost?.amount_last_month) {
                    extraCostInner.amount_last_month = cost?.amount_last_month;
                }

                return extraCostInner;
            } else if (cost?.applicable_tenant_rent === "no") {
                extraCostInner.applicable_tenant_rent = false;

                if (cost?.amount_first_month) {
                    extraCostInner.amount_first_month = cost?.amount_first_month;
                }

                if (cost?.amount_last_month) {
                    extraCostInner.amount_last_month = cost?.amount_last_month;
                }

                return extraCostInner;
            }
        });
        payload.extra_costs = extraCost;
    }

    const res = await api(
        "put",
        endPoints?.CONTRACT + `${company}/${contract}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertyContractSlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);

}

const getPropertyContractAction = (id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY_CONTRACT + `/${id}/`,
    )
    if (res?.success) {
        const results = res?.data?.results;
        dispatch(setPropertyContractSlice(results))
        onRes && onRes(results)
    }
}

const getOptionMaturityAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT_MATURITY + `${id}/`,
    );
    onRes(res)
}

const updateOptionMaturityAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.CONTRACT_MATURITY + `${id}/`,
        data
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteOptionMaturityAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.CONTRACT_MATURITY + `${id}/`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertyContractSlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
}

const getExtraCostAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT_EXTRA_COST + `${id}/`,
    );
    onRes(res)
}

const updateExtraCostAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.CONTRACT_EXTRA_COST + `${id}/`,
        data
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteExtraCostAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.CONTRACT_EXTRA_COST + `${id}/`,
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
}

const contractListAction = (company, rows, page, search, contractId, maturityType, rentRiseType, depositType, baseStatus, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT + `${company}/?limit=${rows}&page=${page}&search=${search}&contract_id=${contractId || ""}&maturity_type=${maturityType || ""}&rent_rise_type=${rentRiseType || ""}&deposit_type=${depositType || ""}&base_status=${baseStatus || "active"}`,
    );
    if (res?.success) {
        let options = res?.data?.results?.map((item) => {
            return { ...item, landlord_name: item?.landlord_name }
        })
        onRes({ options: options, count: res?.data?.count })
    }
};

const getContractAction = (company, contract, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT + `${company}/${contract}`,
    );

    if (res?.success) {
        onRes && onRes(res?.data?.results)
        dispatch(setContractSlice(res?.data?.results));
    }
};

const deleteContractAction = (company, contract, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.CONTRACT + `${company}/${contract}`,
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)
}

const archiveContractAction = (company, contracts, action, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "patch",
        endPoints?.CONTRACT + `${company}/${action}/`,
        {ids: contracts}
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
    setDeleteModal(false)
}

const getContractPropertiesActions = (contract, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CONTRACT_PROPERTY_LIST + `/${contract}/`,
    );

    if (res?.success) {
        onRes(res?.data?.results)
    }
}

export {
    addContractAction,
    searchContractAction,
    contractSuggestions,
    updateContractAction,
    getPropertyContractAction,
    addRoomAndCostAction,
    contractListAction,
    getContractAction,
    deleteContractAction,
    getContractPropertiesActions,
    archiveContractAction,

    //maturities
    getOptionMaturityAction,
    updateOptionMaturityAction,
    deleteOptionMaturityAction,

    //extra cost
    getExtraCostAction,
    updateExtraCostAction,
    deleteExtraCostAction,
}