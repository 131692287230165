import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
export default function CommonViewTable({
  title,
  list,
  columns,
  actions,
  header,
  editingRows,
  dataKey,
  onRowEditChange,
  rowEditValidator,
  handleSave,
  ignoreHeaders
}) {

  return (
    <CustomForm title={title} header={header && header()} titleCol={8} col={4}>
      <DataTable
        className="col-12 px-0 properties-table-list table-error-align"
        value={list}
        editMode="row"
        dataKey={dataKey || "id"}
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        {columns?.map((col, index) => {
          return (
            <Column
              key={index}
              field={col?.accessor}
              header={ignoreHeaders? null : col?.name}
              editor={col?.editor}
              headerClassName={ignoreHeaders? null : "custom-header"}
              body={col?.body}
              style={col?.style}
              // style={{ minWidth: "200px" }}
            ></Column>
          )
        })}
        {actions && <Column
          header="Action"
          rowEditor
          headerClassName="custom-header"
          body={(rowData, options) => actions(rowData, options)}
        ></Column>}
      </DataTable>
    </CustomForm>
  );
}
