import React, { useEffect, useMemo, useState } from "react";
import CommonViewTable from "../Tables/CommonViewTable";
import { getAnnualStatementListByPropertyAction } from "store/actions/AnnualStatementActions";
import { useDispatch, useSelector } from "react-redux";
import { canAccess, getUserInfo } from "services/auth";
// import { setUserProfile } from "store/slices/userSlice";
import { getStatusColors } from "utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import CustomOverlay from "shared/CustomOverlay";
import CustomButton from "shared/CustomButton";
export default function PropertyAnnualStatementList({ propertyId }) {
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [statementList, setStatementList] = useState([]);
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        name: "Year",
        accessor: "year_of_statement",
        value: true,
        frozen: true,
        sortable: false,
        dataType: "date",
      },
      {
        name: "In-status",
        accessor: "status",
        value: true,
        body: (options) => statusBodyTemplate(options?.status),
      },
      {
        name: "Out-status",
        accessor: "",
        value: true,
      },
      {
        name: "Property ID",
        accessor: "property_id",
        sortable: false,
        value: true,
      },
      {
        name: "Type",
        accessor: "type",
        value: true,
      },
    ],
    [statementList]
  );

  const statusBodyTemplate = (status) => {
    return <span className={getStatusColors(status)}>{status}</span>;
  };

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      // dispatch(setUserProfile(info));
    }
  }, []);

  useEffect(() => {
    if (profile?.company) {
      getAnnualStatementList(profile?.company);
    }
  }, [profile]);

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() =>
              navigate(`/real-estate/annualstatement/view/${d.id}/${propertyId}`)
            }
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const getAnnualStatementList = (company) => {
    dispatch(
      getAnnualStatementListByPropertyAction(company, propertyId, (res) => {
        setStatementList(res?.options);
      })
    );
  };

  const header = () => {
    return (<div className="w-12 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 mr-3"></div>
        <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Annual Statement"
            onClick={() => navigate(`/real-estate/annualstatement/add/${propertyId}`)}
        />
    </div>)
};

  return (
    <>
      <CommonViewTable
        title={"Annual Statement"}
        list={statementList}
        columns={columns}
        header={canAccess("change_property") && header}
        actions={canAccess("view_property") && actions}
      />
    </>
  );
}
