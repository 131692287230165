import React from "react";
import InternalRentSettingsContainer from "./InternalRentSetttingsContainer";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomForm, CustomInput } from "shared/AllInputs";

const InternalRentSettingsFrom = () => {

    const {
        data,
        handleChange
    } = InternalRentSettingsContainer()
    return (
        <Page
            title={"Settings"}
        >
            <CustomCard title="Internal Rent Settings">
                <CustomForm>
                    <CustomDropDown data={data} options={[]} name="priceType" label={"Select Price Type"}/>
                    <CustomInput  data={data} name="value" label={"Enter Value"}/>
                </CustomForm>
            </CustomCard>
        </Page>
    )
}

export default InternalRentSettingsFrom