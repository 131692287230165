import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useMemo, useState } from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomAmountViewInput, CustomViewInput } from "shared/AllViewInputs";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "shared/CustomButton";
import CustomCard from "shared/CustomCard";
import CustomModal from "shared/Modals/CustomModal";
import Page from "shared/Page";
import CommonViewTable from "views/Tables/CommonViewTable";
import { Chart } from 'primereact/chart';

const AutomatedRentCalculation = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const header = (
        <div >
            <CustomButton
                type="button"
                label="View Regulation Table"
                onClick={() => setIsOpen(true)}
            />
        </div>
    );

    const FormHeader = () => {
        return (
            <div className="flex align-items-center col-12 justify-content-between">
                <h1 className="title">Property Costs</h1>
                {header}
            </div>
        );
    };

    const accordianHeader = (tier, index) => {
        return (
            <div className="flex align-items-center justify-content-between w-full ">
                <p className="title">Tier 0{index + 1}</p>
                <p className="title">
                    {tier?.name}
                </p>
                <div className="flex gap-3 w-1 justify-content-end title">
                    {tier?.amount}
                </div>
            </div>
        );
    };

    const tiers = [
        {
            name: "Base Rent- Maintenance",
            amount: 53880,
            tier: "Tier 1"
        },
        {
            name: "Heating",
            amount: 12380,
            tier: "Tier 2"
        },
        {
            name: "Admin Fee",
            amount: 12380,
            tier: "Tier 3"
        },
        {
            name: "Unregulated Costs",
            amount: 12380,
            tier: "Tier 4"
        },
    ]

    const tiers1 = [
        {
            tier: "Internal Rent",
            subtitle: true,
        },
        {
            name: "Base Rent",
            amount: 36050,
            tier: "Tier 1"
        },
        {
            name: "Maintenance Rent",
            amount: 10050,
            tier: "Tier 1"
        },
        {
            name: "Sum",
            amount: 32300,
            tier: "Tier 1"
        },
        {
            tier: "Our Rent",
            subtitle: true,
        },
        {
            name: "Our Desired Rent",
            amount: 46438,
            tier: "Tier 1"
        },
        {
            name: "Maintenance costs",
            amount: 10050,
            tier: "Tier 1"
        },
        {
            name: "Sum",
            amount: 56438,
            tier: "Tier 1"
        },
        {
            name: "Government Review Penalty",
            amount: 2558,
            tier: "Tier 1",
            className: "text-red font-bold"
        },
        {
            name: "",
            amount: 538,
            tier: "Tier 1",
            className: "text-green font-bold"
        },
        {
            tier: "After Deduction",
            subtitle: true,
        },
        {
            name: "Base Rent",
            amount: 439,
            tier: "Tier 1"
        },
        {
            name: "Maintenance costs",
            amount: 10050,
            tier: "Tier 1"
        },
        {
            name: "Sum",
            amount: 53838,
            tier: "Tier 1"
        },
    ]

    const tiers2 = [
        {
            name: "Heating Cost",
            amount: 12380,
            tier: "Tier 2"
        },
        {
            name: "Final",
            amount: 12380,
            tier: "",
            className: "text-green font-bold"
        },
    ]

    const tiers3 = [
        {
            name: "Administrative cost",
            amount: 12380,
            tier: "Tier 3"
        },
        {
            name: "Final",
            amount: 12380,
            tier: "",
            className: "text-green font-bold"
        },
    ]

    const tiers4 = [
        {
            name: "Energy Cost",
            amount: 53880,
            tier: "Tier 4"
        },
        {
            name: "Internet Cost",
            amount: 53880,
            tier: "Tier 4"
        },
        {
            name: "Furniture Cost",
            amount: 53880,
            tier: "Tier 4"
        },
        {
            name: "Final",
            amount: 53880,
            tier: "",
            className: "text-green font-bold"
        },
    ]

    const columns = useMemo(() => [
        {
            name: "Tier",
            accessor: "tier",
            style: { minWidth: "200px" },
            body: (options) => <span className={options?.subtitle ? `font-bold` : null}>{options?.tier}</span>,
        },
        {
            name: "Cost",
            accessor: "name",
            style: { minWidth: "200px" },
            body: (options) => <span className={`${options.className}`}>{options?.name}</span>,
        },
        {
            name: "Amount",
            accessor: "amount",
            style: { minWidth: "200px" },
            body: (options) => options?.amount && <CustomAmountViewInput value={options?.amount} name="amount" ignoreLabel />
        }
    ], [])

    const body = () => {
        return <>
            <CommonViewTable columns={columns} list={tiers} />
        </>
    }

    // useEffect(() => {
    //     const documentStyle = getComputedStyle(document.documentElement);
    //     const textColor = documentStyle.getPropertyValue('--text-color');
    //     const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    //     const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    //     const data = {
    //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //         datasets: [
    //             {
    //                 label: 'My First dataset',
    //                 backgroundColor: "#0094DA",
    //                 borderColor: "#0094DA",
    //                 data: [65, 59, 80, 81, 56, 55, 40]
    //             },
    //             {
    //                 label: 'My Second dataset',
    //                 backgroundColor: "#959595",
    //                 borderColor: "#959595",
    //                 data: [28, 48, 40, 19, 86, 27, 90]
    //             }
    //         ]
    //     };
    //     const options = {
    //         maintainAspectRatio: false,
    //         aspectRatio: 0.8,
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     fontColor: textColor
    //                 }
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 ticks: {
    //                     color: textColorSecondary,
    //                     font: {
    //                         weight: 500
    //                     }
    //                 },
    //                 grid: {
    //                     display: false,
    //                     drawBorder: false
    //                 }
    //             },
    //             y: {
    //                 ticks: {
    //                     color: textColorSecondary
    //                 },
    //                 grid: {
    //                     color: surfaceBorder,
    //                     drawBorder: false
    //                 }
    //             }
    //         }
    //     };

    //     setChartData(data);
    //     setChartOptions(options);
    // }, []);

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    type: 'bar',
                    label: 'Dataset 1',
                    backgroundColor: "#0094DA",
                    data: [50, 25, 12, 48, 90, 76, 42]
                },
                {
                    type: 'bar',
                    label: 'Dataset 2',
                    backgroundColor: "#202020",
                    data: [21, 84, 24, 75, 37, 65, 34]
                },
                {
                    type: 'bar',
                    label: 'Dataset 3',
                    backgroundColor: "#959595",
                    data: [41, 52, 24, 74, 23, 21, 32]
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: true
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    // stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    // stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },

            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <Page title={"Automated Rent Calculation"} description={`Let’s add your Rent`}>
            <CustomCard header={FormHeader()}>
                <CustomForm>
                    <CustomViewInput value="Threshhold from above" ignoreLabel />
                    <span className="text-xl font-bold">
                        Total: 1276,80
                    </span>
                    {tiers?.map((tier, index) => {
                        return (
                            <Accordion className="w-full mt-3">
                                <AccordionTab
                                    headerClassName="accordion-role-list"
                                    headerTemplate={() => accordianHeader(tier, index)}
                                >
                                    {index === 0 ? <>
                                        <Chart type="bar" data={chartData} options={chartOptions} />
                                        <CommonViewTable columns={columns} list={tiers1} ignoreHeaders />
                                    </>
                                        : index === 1 ? <CommonViewTable columns={columns} list={tiers2} ignoreHeaders /> : index === 2 ? <CommonViewTable columns={columns} list={tiers3} ignoreHeaders /> : <CommonViewTable columns={columns} list={tiers4} ignoreHeaders />}
                                </AccordionTab>
                            </Accordion>
                        )
                    })}
                </CustomForm>
            </CustomCard>
            <Buttonlayout>
                <CustomButton label="Offer Property" />
                <CustomButtonOutlined label="Cancel" />
            </Buttonlayout>
            <CustomModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                heading={"Regulation Tier"}
                body={body}
                width={'50vw'}
                ignoreCancel
            />
        </Page>
    )
}

export default AutomatedRentCalculation