
export const getTenantName = (tier) => {
    switch (tier) {
        case 1:
            return "1 Person";
        case 2:
            return "2 Person";
        case 3:
            return "3 Person";
        case 4:
            return "4 Person";
        case 5:
            return "5 Person";
        case 6:
            return "6 Person";
        case 7:
            return "6+ Person";
        default:
            return tier
    }
}