import React from "react";
import { CustomForm } from "shared/AllInputs";

const CustomAddTable = ({ title, header, columns }) => {
    //editor={col?.editor}
    return (
        <>
            <CustomForm title={title || null} header={header || null}>
                <div className="overflow-auto">
                    <table className="financial-table editor-container w-12 mt-3 table-error-align">
                        <thead>
                            <tr>
                                {columns?.map((col, index) => {
                                    return (
                                        <th key={index}>{col?.name}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody id="" className="cell-width">
                            <tr>
                                {columns?.map((col, index) => {
                                    return (
                                        <td key={index}>
                                            {col?.editor()}
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CustomForm>
        </>
    )
}

export default CustomAddTable