import React, { useState } from "react";
import formValidation from "utils/validations";

const InternalRentSettingsContainer = () => {

    const [data, setData] = useState({
        priceType: "",
        value: "",
    })

    const handleChange = ({name, value}) => {
        const formValidation = formValidation(name, value, data)
        setData((prev) => ({...prev, [name]: value, formValidation}))
    }

    return{
        data,
        handleChange,
    }
}

export default InternalRentSettingsContainer
