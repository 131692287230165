import React from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomNumberViewInput, CustomViewInput } from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "assets/images/icons/editicon.png";
import CustomRoomTable from "views/Tables/CustomRoomTable";
import CustomEnergyTable from "views/Tables/CustomEnergyTable";
import { canAccess } from "services/auth";
import ViewContractAndUnitFiles from "./ViewContractAndUnitFiles";
import SizeM2 from "assets/images/svg/SizeM2.svg"
const ViewBuildingUnit = ({
  data,
  allRooms,
  setAllRooms,
  allEnergyMeters,
  setAllEnergyMeters,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/property/edit/building-unit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm
        title="Building Unit"
        header={canAccess("change_property") && header()}
      >
        <CustomViewInput name="unitType" data={data} />
        <CustomViewInput name="location" data={data} />
        <CustomViewInput name="floor" data={data} label={"Floor No"} />
        <CustomNumberViewInput name="size" data={data} label={"Size"} spanIcon={SizeM2}/>
        <CustomViewInput name="waterHeating" data={data} />
        <CustomViewInput name="heatingTechnology" data={data} />
      </CustomForm>
      <CustomRoomTable
        id={data?.id}
        maxSize={data?.size}
        title={"Rooms"}
        allRooms={allRooms}
        setAllRooms={setAllRooms}
      />
      <CustomEnergyTable
        id={data?.id}
        title={"Energy Meters"}
        allEnergyMeters={allEnergyMeters}
        setAllEnergyMeters={setAllEnergyMeters}
      />
      <ViewContractAndUnitFiles files={data?.building_document} />
    </>
  );
};

export default React.memo(ViewBuildingUnit);
