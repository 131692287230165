import React, { useMemo, useState } from "react";
import { CustomCalander, CustomDropDown, CustomForm, CustomInput, CustomSearch } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import AddCoTenant from "shared/Components/AddCoTenantTable";
import CustomTableContainer from "views/Tables/CustomTableContainer";

const TenantContractForm = () => {
    const [search, setSearch] = useState("")
    const [isSearch, setIsSearch] = useState(false)
    const [allTenants, setAllTenants] = useState([{ firstName: "", lastName: "", clientType: "", dob: "", email: "", phone: "" }])
    const [data, setData] = useState({
        movingDate: "",
        contractType: "",
    })

    const columns = useMemo(() => [
        {
            name: "Property ID",
            accessor: "tenant_id",
            value: true,
            frozen: true,
            filter: true,
            // filterElement: (option) => CommonTextFilter(option, "tenant_id"),
        },
        {
            name: "Status",
            accessor: "tenant_name",
            sortable: false,
            value: true,
            filter: true,
            // filterElement: (option) => CommonTextFilter(option, "tenant_name"),
        },
        {
            name: "Address",
            accessor: "tenant_type",
            ssortable: false,
            value: true,
            filter: true,
            // filterElement: (option) => CommonDropdownFilter(option, "tenant_type", TenantTypes),
        },
        {
            name: "Distance CTC",
            accessor: "status",
            value: true,
            sortable: false,
            filter: false,
            className: "capitalize",
            // body: (option) => {
            //     return <span className={getStatusColors(option?.status)}>{option?.status}</span>;
            // },
            // filterElement: (option) => CommonDropdownFilter(option, "status", TenantStatusTypes),
        },
        {
            name: "Update Dtae",
            accessor: "phone_number",
            sortable: false,
            value: true,
        },
        {
            name: "Rent",
            accessor: "property_id",
            sortable: false,
            value: true,
        },
    ], [])

    const handleChange = ({ name, value }) => {
        setData((prev) => ({ ...prev, [name]: value }))
    }

    const header = () => {
        return (
            <CustomSearch
                name="search"
                value={search}
                // onChange={({ value }) => {
                //   setFirst(0);
                //   setSearch(value);
                //   debouncedOnChange.current(
                //     profile?.company,
                //     10,
                //     1,
                //     value,
                //     filters?.tenant_id,
                //     filters?.tenant_name,
                //     filters?.tenant_type,
                //     filters?.status,
                //     baseStatus
                //   );
                // }}
                isSearch={isSearch}
                placeholder="Search Property"

            />
        );
    };

    return (
        <>
            <CustomForm title="Contracts">
                <CustomCalander
                    name="movingDate"
                    data={data}
                    onChange={handleChange}
                />
                <CustomDropDown
                    name="contractType"
                    options={[
                        { name: "Room", value: "room" },
                        { name: "Full Property", value: "fullProperty" },
                    ]}
                    label={"Full Property Or Room"}
                    data={data}
                    onChange={handleChange}
                />
                {data?.contractType === "fullProperty" && <AddCoTenant allValues={allTenants} setAllValues={setAllTenants} />}
                <CustomViewInput label={"Clients moving in"} name="movingIn" />
                <CustomViewInput label={"Clients care service"} name="movingIn" />
            </CustomForm>
            <CustomForm title="Select Property" header={header()} >
                <CustomTableContainer
                    list={[]}
                    columns={columns}
                />

            </CustomForm>
            <CustomForm>
                <CustomInput
                    name="contractAccountingID"
                    options={[
                        { name: "Room", value: "room" },
                        { name: "Full Property", value: "fullProperty" },
                    ]}
                    label={"Contract Accounting ID"}
                    data={data}
                    onChange={handleChange}
                />
                <CustomDropDown
                    name="MaintenanceCost"
                    options={[
                        { name: "Lumpsum", value: "Lumpsum" },
                    ]}
                    label={"Maintenance Cost"}
                    data={data}
                    onChange={handleChange}
                />
                <CustomDropDown
                    name="HeatingCost"
                    options={[
                        { name: "Advance payment", value: 'advancePayemt' }
                    ]}
                    label={"Heating Cost"}
                    data={data}
                    onChange={handleChange}
                />
            </CustomForm>
        </>
    )
}

export default TenantContractForm