import React, { useRef } from "react";
import InputLayout from "./InputLayout";
import { Chips } from "primereact/chips";
import { truncateText } from "../utils/regex";
import {
  capitalizeCamelCase,
  convertAmountInGerman,
  getFileExtension,
  getFileName,
  handleFormatDate,
} from "../utils/commonFunctions";
import constants from "../constants";
import pdfIcon from "../assets/images/icons/pdfIcon.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { saveAs } from "file-saver";
import { Tooltip } from "primereact/tooltip";
import CustomOverlay from "./CustomOverlay";
import EuroIcon from "assets/images/svg/euroIcon.svg"

const baseURL = constants.baseUrl;
export const CustomViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  icon,
  ignoreLabel,
  spanIcon,
  ...props
}) => {
  let valToShow = value || data?.[name];

  // if (valToShow && isNaN(valToShow)) {
  //   valToShow = formatFieldName(valToShow);
  // }
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      ignoreLabel={ignoreLabel}
    >
      {icon ? icon : ""}
      <div className="flex align-items-center gap-2">
        <div className={inputClass}>{valToShow || "-----"}</div><span>{valToShow ? spanLabel || <img src={spanIcon} /> : null}</span>
      </div>
    </InputLayout>
  );
};

export const CustomImageView = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  image,
  src,
  index,
  ...props
}) => {
  const view = useRef(null);
  const extension = getFileExtension(image || src?.name);
  const imageName = getFileName(image);
  const openImage = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    <>
      <div className="relative">
        {extension === "pdf" ? (
          <>
            <img className="image-view" src={pdfIcon} alt="" />
            <Tooltip
              className="tooltip"
              target={`.tooltip-pdf${index}`}
              content={`${imageName}.${extension}`}
            />
            <p
              className={`tooltip-pdf${index} cursor-pointer`}
              data-pr-classname="tooltip"
            >
              {truncateText(imageName, 10)}
            </p>
          </>
        ) : src ? (
          <img className="image-view" src={URL.createObjectURL(src)} alt="" />
        ) : (
          <img className="image-view" src={`${baseURL}${image}`} alt="" />
        )}
        {image && (
          <i
            className="pi pi-ellipsis-v absolute text-black cursor-pointer"
            style={{ top: "8px", right: "-15px" }}
            onClick={(e) => view.current.toggle(e)}
          ></i>
        )}
        <OverlayPanel ref={view}>
          <ul className="list-none p-0">
            <li
              className="p-2"
              onClick={() => openImage(baseURL + image)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-eye"></i> View
            </li>
            <li
              className="p-2"
              onClick={() => saveAs(baseURL + image, imageName)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-download"></i> Download
            </li>
          </ul>
        </OverlayPanel>
      </div>
    </>
  );
  // }
};

export const NewCustomImageView = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  image,
  src,
  ignoreLabel,
  ignoreError,
  files,
  documentLabel,
  documentType,
  ...props
}) => {
  const view = useRef(null);
  const extension = getFileExtension(image);
  const imageName = getFileName(image);
  const openImage = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    <>
      {/* <InputLayout
        col={col}
        label={label}
        ignoreLabel={ignoreLabel}
        name={name}
        required={required}
        extraClassName={extraClassName}
        data={data}
        ignoreError={ignoreError}
        errorMessage={errorMessage}
      > */}
      <div
        className={`h-auto upload-file-box h-auto ${files?.length > 0 ? "uploaded-file" : ""
          }`}
      >
        <div className={`flex justify-content-between p-3`}>
          {(documentLabel || documentType) && (
            <div className="flex gap-3">
              <i className="pi pi-file"></i>
              <span className="text-sm font-semibold">
                {capitalizeCamelCase(documentLabel || documentType)}
              </span>
            </div>
          )}
        </div>
        {files && files?.length > 0 && (
          <ul className="p-3 m-0 list-none">
            {files?.map((file, index) => (
              <li
                className="border-none w-full mb-2 grid align-items-center upload-list"
                key={index}
              >
                <div className="col-11">
                  {truncateText(getFileName(file?.document), 20)}{"."}
                  {getFileExtension(file?.document)}{" "}
                </div>

                {/* {(onFileDelete || onFileView) && ( */}
                <div className="col-1">
                  <CustomOverlay>
                    <ul className="list-none p-0">
                      <li
                        className="p-2"
                        onClick={() => openImage(baseURL + file?.document)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="pi pi-eye"></i> View
                      </li>
                      <li
                        className="p-2"
                        onClick={() => saveAs(baseURL + file?.document, imageName)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="pi pi-download"></i> Download
                      </li>
                    </ul>
                  </CustomOverlay>
                </div>
                {/* )} */}
              </li>
            ))}
          </ul>
        )}

      </div>
      {/* </InputLayout> */}

      {/* <div className="relative">
        {extension === "pdf" ? (
          <>
            <img className="image-view" src={pdfIcon} alt="" />
            <Tooltip
              className="tooltip"
              target={`.tooltip-pdf`}
              content={imageName}
            />
            <p
              className={`tooltip-pdf cursor-pointer`}
              data-pr-classname="tooltip"
            >
              {truncateText(imageName, 10)}
            </p>
          </>
        ) : src ? (
          <img className="image-view" src={URL.createObjectURL(src)} alt="" />
        ) : (
          <img className="image-view" src={`${baseURL}${image}`} alt="" />
        )}
        {image && (
          <i
            className="pi pi-ellipsis-v absolute text-black cursor-pointer"
            style={{ top: "8px", right: "-15px" }}
            onClick={(e) => view.current.toggle(e)}
          ></i>
        )}
        <OverlayPanel ref={view}>
          <ul className="list-none p-0">
            <li
              className="p-2"
              onClick={() => openImage(baseURL + image)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-eye"></i> View
            </li>
            <li
              className="p-2"
              onClick={() => saveAs(baseURL + image, imageName)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-download"></i> Download
            </li>
          </ul>
        </OverlayPanel>
      </div> */}
    </>
  );
  // }
};

export const CustomNumberViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  icon,
  spanIcon,
  ignoreLabel,
  ...props
}) => {
  const valueToShow = value || data?.[name]
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      ignoreLabel={ignoreLabel}
    >
      {icon && value ? icon : ""}
      <div className="flex align-items-center gap-2">
        <div className={inputClass}>{valueToShow ? convertAmountInGerman(valueToShow) : "-----"}</div><span>{valueToShow ? spanLabel || <img src={spanIcon} /> : null}</span>
      </div>
    </InputLayout>
  );
};

export const CustomViewArrayInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  let totalLength = data?.[name]?.length - 1;
  let dataToView = data?.[name]?.map((info, index) => {
    if (index < totalLength) {
      return `${info}, `;
    } else {
      return info;
    }
  });

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {dataToView || "-----"}
    </InputLayout>
  );
};

export const CustomViewChips = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={"Email"}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {data?.[name] ? (
        <Chips className={`w-full mt-2 chips`} value={data?.[name]} disabled />
      ) : (
        "-----"
      )}
    </InputLayout>
  );
};

export const CustomAmountViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  ignoreLabel,
  ignoreIcon,
  ...props
}) => {
  const val = value || data?.[name];
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      ignoreLabel={ignoreLabel}
    >
      {(!ignoreIcon && val) ? (icon ? <img src={icon} /> : <img src={EuroIcon} />) : ""}{" "}
      {val ? convertAmountInGerman(val) : "-----"}
    </InputLayout>
  );
};

export const CustomDateViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  icon,
  ignoreLabel,
  ...props
}) => {
  let valToShow = value || data?.[name];

  // if (valToShow && isNaN(valToShow)) {
  //   valToShow = formatFieldName(valToShow);
  // }
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      ignoreLabel={ignoreLabel}
    >
      {icon ? icon : ""}
      {handleFormatDate(valToShow) || "-----"} {valToShow ? spanLabel : null}
    </InputLayout>
  )
}
