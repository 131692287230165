import React from "react";
import { CustomForm, CustomRadioInput } from "shared/AllInputs";

const AreaSelectionForm = ({ data, handleChange }) => {

    return (
        <CustomForm title="Area Selection" formClass={"py-2"}>
            <p>Please choose whether you want to use the externally recognized abstract area or the actual area specified in the lease agreement as the maximum for calculating the review tier.</p>
            <CustomRadioInput
                data={data}
                onChange={handleChange}
                extraClassName="md:px-0"
                name="areaSelection"
                htmlFor="Abstract Area"
                value="abstractArea"
                label={"Abstract Area"}
                checked={data?.areaSelection === "abstractArea"}
            />
            <CustomRadioInput
                data={data}
                onChange={handleChange}
                name="areaSelection"
                value="actualArea"
                label="Actual Area"
                htmlFor="Actual Area"
                checked={data?.areaSelection === "actualArea"}
            />
        </CustomForm>
    )
}

export default AreaSelectionForm