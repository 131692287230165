import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPorpertyInfoAction,
  searchPropertyIdAction,
  updatePropertyAction,
} from "store/actions/propertyActions";
import formValidation from "utils/validations";
import {
  addBuildingAction,
  searchBuildingAction,
} from "store/actions/buildingActions";
import {
  checkIsValueGreater,
  handleFileConversion,
  handleSetAddress,
  isFirstDayOfMonth,
  showFormErrors,
} from "utils/commonFunctions";
import {
  addBuildingUnitAction,
  updateBuildingUnitAction,
} from "store/actions/buildingUnitActions";
import {
  addLandlordAction,
  getFurnitureListAction,
  getLandlordContactsAction,
  getPropertyLandlordAction,
  landlordDropdownAction,
  // searchLandlordAction,
  updateLandlordAction,
} from "store/actions/landlordActions";
import {
  addContractAction,
  addRoomAndCostAction,
  getPropertyContractAction,
  searchContractAction,
  updateContractAction,
} from "store/actions/contractActions";
import { debounce } from "lodash";
import {
  checkBankErrors,
  // checkBankErrors,
  checkContactErrors,
  checkEnergyMeterErrors,
  checkExtraCostErrors,
  checkFurnitureErrors,
  // checkFurnitureErrors,
  checkOptionalMaturtiesErrors,
  checkRoomErrors,
} from "shared/Components/commonFunctions";
import {
  handleSetBuildingOptions,
  handleSetBuildingSturctureData,
  handleSetBuildingUnitData,
  handleSetContractData,
  handleSetFurniture,
  handleSetLandlordData,
  handleSetPropertyData,
  handleSetRentData,
} from "../PropertyCommonFunctions";
import {
  setPropertyContractSlice,
  setPropertySlice,
} from "store/slices/propertySlice";

const ViewEditPropertyContainer = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [activeIndex, setActiveIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [tabToActive, setTabToActive] = useState("");
  const [isWarning, setIsWarning] = useState(false)
  const routes = [
    { index: 0, route: "building-structure" },
    { index: 1, route: "building-unit" },
    { index: 2, route: "landlord-info" },
    { index: 3, route: "contractual-info" },
    { index: 4, route: "rent-and-cost" },
    { index: 5, route: "annual-statement" },
  ];
  const [action, setAction] = useState("");
  const [route, setRoute] = useState("");
  // const [user, setUser] = useState("");
  const userProfileRef = useRef();
  const [loading, setLoading] = useState(false);
  const propertyDataRedux = useSelector((state) => state?.property?.property);
  const buildingStructureDataRedux = propertyDataRedux?.building_data;
  const buildingUnitDataRedux = propertyDataRedux?.building_unit;
  const landlordDataRedux = propertyDataRedux?.landlordData;
  const ContractDataRedux = propertyDataRedux?.contractData;

  const modalContent = () => {
    return (
      <span>Do you want to save changes? This action cannot be undone.</span>
    );
  };

  useEffect(() => {
    if (profile?.company) {
      getPropertyInfo(profile?.company);
    }
  }, [profile]);

  useEffect(() => {
    userProfileRef.current = profile; // Update the ref with the latest userProfile
  }, [profile]);

  useEffect(() => {
    let path = location?.pathname.split("/");
    let lastSegment = path[path.length - 2];
    let action = path[path.length - 3];
    setAction(action);
    setRoute(lastSegment);
    routes?.map((item) => {
      if (item.route === lastSegment && activeIndex !== item?.index) {
        setActiveIndex(item?.index);
      } else if (lastSegment === "property") {
        setActiveIndex(activeIndex || 0);
      }
    });
  }, [location?.pathname]);

  const handleTabChange = (index) => {
    routes?.map((item) => {
      if (item?.index === index) {
        navigate(`/real-estate/property/view/${item?.route}/${params?.id}`);
        setActiveIndex(item?.index);
      }
    });
  };

  //Property
  const [propertyIdOptions, setPropertyIdOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  // const [propertyId, setPropertyId] = useState(params?.id);
  const [PropertyData, setPropertyData] = useState({
    propertyId: "",
    address: "",
    zipCode: "",
    city: "",
    houseNo: "",
    street: "",
    cityDivision: "",
    country: "",
  });

  const [buildingStuctureData, setBuildingStuctureData] = useState({
    building: "",
    buildingName: "",
    buildingType: "",
    buildingMaxFloor: "",
    staircaseAccess: "",
    size: "",
    longitude: "",
    latitude: "",
    address: "",
    street: "",
    houseNo: "",
    zipCode: "",
    country: "",
    city: "",
    cityDivision: "",
    document: [],
  });

  const [fileKey, setFileKey] = useState(0);
  const [sturcureDocuments, setStructureDocuments] = useState([]);

  const handleStructureFileChange = async (event) => {
    const file = event.target.files[0];
    setStructureDocuments([...sturcureDocuments, file]);

    const convertedFile = await handleFileConversion(file);
    let douments = [...buildingStuctureData.document];
    douments.push({ document: convertedFile });
    setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }));
  };

  const handleStructureFileDelete = (index) => {
    // let arr = [...sturcureDocuments];
    // arr?.splice(index, 1);
    // setStructureDocuments(arr);

    let douments = [...buildingStuctureData.document];
    douments?.splice(index, 1);
    setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }));

    // if (sturcureDocuments.length == 1) {
    //   setStructureDocuments([]);
    //   setFileKey((prevKey) => prevKey + 1);
    // }
  };

  const [buildingOptions, setBuildingOptions] = useState([
    { name: "Add New", value: "add_new" },
  ]);

  const getPropertyInfo = (company) => {
    let options = [{ name: "Add New", value: "add_new" }];
    dispatch(
      getPorpertyInfoAction(company, params?.id, dispatch, (res) => {
        handleSetPropertyData(res, setPropertyData);
        const buildingStrucure = res?.building_data;
        options.push({
          ...buildingStrucure,
          name: buildingStrucure?.name,
          value: buildingStrucure?.id,
        });
        setBuildingOptions(options);
        handleSetBuildingSturctureData(
          "",
          buildingStrucure,
          setBuildingStuctureData,
          setStructureDocuments
        );
      })
    );
  };

  const propertyChange = ({ name, value }) => {
    // if (name === "propertyId" && value) {
    //   propertyIdChange.current(value);
    // }

    const formErrors = formValidation(name, value, PropertyData);
    setPropertyData((prev) => ({ ...prev, [name]: value, formErrors }));

    if (name === "address") {
      setSelectedAddress("");
    }

    if (
      name == "houseNo" ||
      name == "street" ||
      name == "zipCode" ||
      name == "city" ||
      name == "cityDivision" ||
      name == "country"
    ) {
      setBuildingStuctureData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleBuildingSearch = (company, lng, lat) => {
    let options = [{ name: "Add New", value: "add_new" }];
    dispatch(
      searchBuildingAction(company, lng, lat, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          info?.map((item) => {
            options.push({
              ...item,
              name: item?.name,
              value: item?.id,
            });
          });
        }
        setBuildingOptions(options);
      })
    );
  };

  const buildingIdChange = useRef(debounce(handleBuildingSearch, 1000));

  useMemo(() => {
    if (selectedAddress) {
      handleSetAddress(selectedAddress, PropertyData, setPropertyData);
      handleSetAddress(
        selectedAddress,
        buildingStuctureData,
        setBuildingStuctureData
      );
      if (selectedAddress?.lng && selectedAddress?.lat) {
        buildingIdChange.current(
          profile?.company,
          selectedAddress?.lng,
          selectedAddress?.lat
        );
        setBuildingStuctureData((prevState) => ({
          ...prevState,
          id: "",
        }));
      }
    }
  }, [selectedAddress]);

  const handlePropertySuggestions = (value) => {
    let payload = {
      property_id: value,
    };

    dispatch(
      searchPropertyIdAction(payload, (res) => {
        if (!res?.success) {
          const formErrors = { ["propertyId"]: res?.message };
          setPropertyData((prev) => ({
            ...prev,
            ["propertyId"]: value,
            formErrors,
          }));
          if (res?.data?.results) {
            setPropertyIdOptions(res?.data?.results);
          }
        }
      })
    );
  };

  const propertyIdChange = useRef(debounce(handlePropertySuggestions, 1000));

  const propertySubmit = () => {
    if (showFormErrors(PropertyData, setPropertyData)) {
      dispatch(
        updatePropertyAction(
          profile?.company,
          params?.id,
          PropertyData,
          setLoading,
          dispatch,
          (res) => {
            handleSetPropertyData(res, setPropertyData);
            navigate(`/real-estate/property/view/property/${params?.id}`);
          }
        )
      );
    }
  };

  //building structure
  const BuildingStuctureHandleChange = ({ name, value, ...rest }) => {
    let ignoreInputs = [
      "document",
      "longitude",
      "latitude",
      "address",
      "street",
      "houseNo",
      "zipCode",
      "country",
      "city",
      "cityDivision",
    ];
    // const { type } = rest?.target;
    const type = rest?.target?.type || null
    const formErrors = formValidation(
      name,
      value,
      buildingStuctureData,
      ignoreInputs
    );

    if (type === "file" && value) {
      // setBuildingStuctureData((prev) => ({...prev, [name]: files[0],formErrors,}));
      let douments = [...buildingStuctureData.document];
      douments.push({ type: rest?.documentType, document: value });
      setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }));
    } else if (name === "building") {
      const selectedBuilding = buildingOptions.find(
        (item) => item?.id === value
      );
      if (selectedBuilding) {
        handleSetBuildingSturctureData(
          value,
          selectedBuilding,
          setBuildingStuctureData
        );
      } else {
        setBuildingStuctureData((prev) => ({
          ...prev,
          [name]: value,
          ["buildingMaxFloor"]: "",
          ["staircaseAccess"]: "",
          ["size"]: "",
          ["document"]: [],
          formErrors,
        }));
      }
    } else {
      setBuildingStuctureData((prev) => ({
        ...prev,
        [name]: value,
        formErrors,
      }));
    }
  };

  const BuildingStuctureSubmit = async () => {
    setIsWarning(false)
    if (
      showFormErrors(buildingStuctureData, setBuildingStuctureData, [
        "document",
        "longitude",
        "latitude",
        "address",
        "street",
        "houseNo",
        "zipCode",
        "country",
        "city",
        "cityDivision",
      ])
    ) {
      // let data = {
      //   ...buildingStuctureData,
      //   longitude: selectedAddress?.lng || buildingStuctureData?.longitude,
      //   latitude: selectedAddress?.lat || buildingStuctureData?.latitude,
      //   address: selectedAddress?.address || buildingStuctureData?.address,
      // };
      // if (buildingStuctureData?.building !== "add_new") {
      //   dispatch(
      //     updateBuildingAction(
      //       userProfile?.company,
      //       buildingStuctureData?.building,
      //       payload,
      //       (res) => {
      //         if (res?.success) {
      //           const info = res?.data?.results;
      //           handleSetBuildingSturctureData(
      //             "",
      //             info,
      //             setBuildingStuctureData,
      //             setStructureDocuments
      //           );
      //           setMsgToShow({ type: "success", message: res?.message });
      //           navigate(
      //             `/real-estate/property/view/building-structure/${propertyId}`
      //           );
      //         } else {
      //           setMsgToShow({ type: "error", message: res?.message });
      //         }
      //         setLoading(false);
      //       }
      //     )
      //   );
      // } else {
      dispatch(
        addBuildingAction(
          profile?.company,
          buildingStuctureData,
          setLoading,
          dispatch,
          (res) => {
            handleSetBuildingSturctureData(
              "",
              res,
              setBuildingStuctureData,
              setStructureDocuments
            );
            handleSetBuildingOptions(res, buildingOptions, setBuildingOptions); //17-9-24
            dispatch(setPropertySlice(res));
            const isActive =
              tabToActive !== null &&
              tabToActive !== undefined &&
              (typeof tabToActive === "number" || tabToActive === 0);
            isActive
              ? handleTabChange(tabToActive)
              : navigate(
                `/real-estate/property/view/building-structure/${params?.id}`
              );
          }
        )
      );
    }
  };

  //building unit
  const [buildingUnitData, setBuildingUnitdata] = useState({
    id: "",
    // name: "",
    unitType: "Residential",
    location: "",
    floor: "",
    waterHeating: "",
    size: "",
    heatingTechnology: "",
    rooms: "",
    energy_meters: "",
    building_document: [
      { handover_protocol: [] },
      { key_protocol: [] },
      { floor_plan: [] },
      { photos: [] },
      { energy_bill: [] },
      { energy_contract: [] },
      { energymeter_replacement: [] },
    ],
  });

  //rooms
  const [allRooms, setAllRooms] = useState([]);

  //energy meters
  const [allEnergyMeters, setAllEnergyMeters] = useState([]);

  const BuildingUnitChange = ({ name, value, ...rest }) => {
    // const { type } = rest?.target;
    // const { documentType } = rest;
    const type = rest?.target?.type || null
    const documentType = rest?.documentType || null

    let formErrors = formValidation(name, value, buildingUnitData);
    if (type === "file" && value) {
      const documentIndex = buildingUnitData.building_document.findIndex(
        (doc) => Object.keys(doc)[0] === documentType
      );

      if (documentIndex !== -1) {
        const updatedBuildingDocument = [...buildingUnitData.building_document];
        updatedBuildingDocument[documentIndex] = {
          [documentType]: [
            ...updatedBuildingDocument[documentIndex][documentType],
            { type: documentType, document: value },
          ],
        };

        setBuildingUnitdata((prev) => ({
          ...prev,
          building_document: updatedBuildingDocument,
        }));
      }
    } else {
      if (name === "floor") {
        let isGreater = checkIsValueGreater(
          value,
          buildingStuctureData.buildingMaxFloor
        );
        if (isGreater) {
          formErrors = {
            ["floor"]:
              "The floor number must be lower than the maximum floor of the building.",
          };
        } else {
          formErrors = formValidation(name, value, buildingUnitData, [
            "id",
            "name",
          ]);
        }
      }

      if (name === "size") {
        let isGreater = checkIsValueGreater(value, buildingStuctureData.size);
        if (isGreater) {
          formErrors = {
            ["size"]:
              "The size must be lower than the maximum size of the building.",
          };
        } else {
          formErrors = formValidation(name, value, buildingUnitData, ["id"]);
        }
      }

      setBuildingUnitdata((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const handleUnitFileDelete = (index, documentType) => {
    const updatedBuildingDocument = [...buildingUnitData.building_document];

    const documentIndex = buildingUnitData.building_document.findIndex(
      (doc) => Object.keys(doc)[0] === documentType
    );

    const documents =
      buildingUnitData?.building_document[documentIndex][documentType];
    documents.splice(index, 1);
    updatedBuildingDocument[documentIndex] = {
      [documentType]: [...updatedBuildingDocument[documentIndex][documentType]],
      documents,
    };

    setBuildingUnitdata((prev) => ({
      ...prev,
      ["building_document"]: updatedBuildingDocument,
    }));
  };

  const BuildingUnitSubmit = () => {
    const rooms = checkRoomErrors(
      allRooms,
      setAllRooms,
      buildingUnitData?.size
    );
    const energyMeters = checkEnergyMeterErrors(
      allEnergyMeters,
      setAllEnergyMeters
    );

    if (
      showFormErrors(buildingUnitData, setBuildingUnitdata) &&
      !rooms.hasErrors &&
      !energyMeters?.hasErrors
    ) {
      let data = {
        ...buildingUnitData,
        propertyId: params?.id,
        rooms: rooms?.rooms,
        energy_meters: energyMeters?.energyMeters,
      };

      if (buildingUnitData?.id) {
        dispatch(
          updateBuildingUnitAction(
            buildingUnitData?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetBuildingUnitData(
                res,
                setBuildingUnitdata,
                setAllRooms,
                setAllEnergyMeters
              );
              const isActive =
                tabToActive !== null &&
                tabToActive !== undefined &&
                (typeof tabToActive === "number" || tabToActive === 0);
              isActive
                ? handleTabChange(tabToActive)
                : navigate(
                  `/real-estate/property/view/building-unit/${params?.id}`
                );
            }
          )
        );
      } else {
        dispatch(
          addBuildingUnitAction(data, setLoading, dispatch, (res) => {
            handleSetBuildingUnitData(
              res,
              setBuildingUnitdata,
              setAllRooms,
              setAllEnergyMeters
            );
            const isActive =
              tabToActive !== null &&
              tabToActive !== undefined &&
              (typeof tabToActive === "number" || tabToActive === 0);
            isActive
              ? handleTabChange(tabToActive)
              : navigate(
                `/real-estate/property/view/building-unit/${params?.id}`
              );
          })
        );
      }
    }
  };

  //bankaccounts
  const [allBankAccounts, setAllBankAccounts] = useState([]);

  //contacts
  const [landlordOptions, setLandlordOptions] = useState([]);

  const [allContacts, setAllContacts] = useState([]);

  //furniture
  const [allFurniture, setAllFurniture] = useState([]);

  //landlord
  const [landlordAddress, setLandlordAddress] = useState();
  const [landLordData, setLandLordData] = useState({
    id: "",
    landlord_id: "",
    existingLandlord: "",
    landlordName: "",
    landlordType: "",
    address: "",
    street: "",
    houseNo: "",
    zipCode: "",
    city: "",
    cityDivision: "",
    country: "",
    email: "",
    phoneNumber: "",
    // directDebit: "",
    // furnitureProvided: "",
    // landlord_furniture: [],
    landlord_documents: [
      { contact_person: [] },
      { change_of_payments: [] },
      { change_of_property_management: [] },
    ],
  });

  // const [disableLandloard, setDisableLandlord] = useState(false);

  const getLandlordInfo = (id) => {
    dispatch(
      getPropertyLandlordAction(id, dispatch, (res) => {
        handleSetLandlordData(res, setLandLordData);
        dispatch(getLandlordContactsAction(id, (contacts) => {
          setAllContacts(contacts)
        }))
        // setAllBankAccounts,
        // setAllContacts
        // setAllFurniture
        // setDisableLandlord(true);
      },
        (error) => {
          handleSetLandlordData(
            "",
            setLandLordData,
            // setAllContacts
          );
        }
      )
    );


  };

  const LandlordChange = ({ name, value, ...rest }) => {
    const formErrors = formValidation(
      name,
      value,
      landLordData,
      ["existingLandlord"],
      ["phoneNumber", "email"]
    );
    if (name === "landlord_documents" && value) {
      const { documentType } = rest;
      const documentIndex = landLordData?.landlord_documents.findIndex(
        (doc) => Object.keys(doc)[0] === documentType
      );

      if (documentIndex !== -1) {
        const updatedBuildingDocument = [...landLordData?.landlord_documents];
        updatedBuildingDocument[documentIndex] = {
          [documentType]: [
            ...updatedBuildingDocument[documentIndex][documentType],
            { type: documentType, document: value },
          ],
        };

        setLandLordData((prev) => ({
          ...prev,
          landlord_documents: updatedBuildingDocument,
        }));
      }
    } else {
      if (name === "existingLandlord") {
        if (value?.landlord_id) {
          const landlord = value
          // handleSetLandlordData(landlord, setLandLordData, setAllContacts)
          handleSetLandlordData(landlord, setLandLordData)
          setAllContacts([])
        }
        if (value?.landlord_name && !value?.id) {
          setLandLordData({
            id: "",
            existingLandlord: value?.landlord_name,
            landlordName: value?.landlord_name,
            landlordType: "",
            address: "",
            houseNo: "",
            street: "",
            zipCode: "",
            city: "",
            cityDivision: "",
            country: "",
            email: "",
            phoneNumber: "",
            landlord_documents: [
              { contact_person: [] },
              { change_of_payments: [] },
              { change_of_property_management: [] },
            ],
          });
          setAllContacts([]);
        }
        setLandLordData((prev) => ({ ...prev, [name]: value?.landlord_name || value, formErrors }));
      } else {
        setLandLordData((prev) => ({ ...prev, [name]: value, formErrors }));
      }
    }
    // if (name === "existingLandlord" && value) {
    //   landlordIdChange.current(value);
    //   setLandLordData((prevState) => ({
    //     ...prevState,
    //     id: "",
    //   }));
    // }
  };

  const handleLandlordFileDelete = (index, documentType) => {
    const updatedBuildingDocument = [...landLordData.landlord_documents];

    const documentIndex = landLordData.landlord_documents.findIndex(
      (doc) => Object.keys(doc)[0] === documentType
    );

    const documents =
      landLordData?.landlord_documents[documentIndex][documentType];
    documents.splice(index, 1);
    updatedBuildingDocument[documentIndex] = {
      [documentType]: [...updatedBuildingDocument[documentIndex][documentType]],
      documents,
    };

    setLandLordData((prev) => ({
      ...prev,
      ["landlord_documents"]: updatedBuildingDocument,
    }));
  };

  useMemo(() => {
    if (landlordAddress) {
      handleSetAddress(landlordAddress, landLordData, setLandLordData, [
        "longitude",
        "latitude",
      ]);
    }
  }, [landlordAddress]);

  // useEffect(() => {
  //   if (!landLordData?.landlord_furniture?.length) {
  //     if (
  //       landLordData?.furnitureProvided === "yes" &&
  //       allFurniture.length === 0
  //     ) {
  //       setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "" }]);
  //     } else if (landLordData?.furnitureProvided === "no") {
  //       setAllFurniture([]);
  //     }
  //   }
  // }, [landLordData]);

  const handleLandlordSearch = (company, value) => {
    dispatch(
      // searchLandlordAction(company, value, (res) => {
      //   setLandlordOptions(res);
      // })
      landlordDropdownAction(company, value, "", (res) => {
        if (res?.length) {
          const options = [
            { landlordName: `${"Add New: " + value}`, landlord_name: value },
          ];
          res?.map((item) => {
            options.push({
              ...item,
              landlordName: `${item?.landlord_name + " (" + item?.landlord_id + ")"
                }`,
            });
          });
          setLandlordOptions(options);
        } else {
          const options = [
            { landlordName: `${"Add New: " + value}`, landlord_name: value },
          ];
          setLandlordOptions(options);
        }
      })
    );
  };

  const landlordIdChange = useRef(
    debounce((value) => {
      handleLandlordSearch(userProfileRef.current?.company, value);
    }, 1000)
  );

  const LandlordSubmit = () => {
    // const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts);
    // const furniture = checkFurnitureErrors(allFurniture, setAllFurniture);
    const contacts = checkContactErrors(allContacts, setAllContacts);
    if (
      showFormErrors(
        landLordData,
        setLandLordData,
        ["existingLandlord", "landlord_id"],
        ["phoneNumber", "email"]
      ) &&
      // !bankAccounts?.hasErrors &&
      !contacts?.hasErrors
      // !furniture?.hasErrors
    ) {
      let data = {
        ...landLordData,
        propertyId: params?.id,
        // bank_accounts: bankAccounts?.bankAccounts,
        contacts: contacts?.contacts,
        // landlord_furniture: furniture?.furniture,
      };
      setLoading(true);
      if (landLordData?.id) {
        dispatch(
          updateLandlordAction(
            profile?.company,
            landLordData?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetLandlordData(
                res,
                setLandLordData,
                // setAllBankAccounts,
                // setAllContacts
                // setAllFurniture
              );
              const isActive =
                tabToActive !== null &&
                tabToActive !== undefined &&
                (typeof tabToActive === "number" || tabToActive === 0);
              isActive
                ? handleTabChange(tabToActive)
                : navigate(
                  `/real-estate/property/view/landlord-info/${params?.id}`
                );
            }
          )
        );
      } else {
        dispatch(
          addLandlordAction(
            profile?.company,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetLandlordData(
                res,
                setLandLordData,
                // setAllBankAccounts,
                // setAllContacts
                // setAllFurniture
              );
              const isActive =
                tabToActive !== null &&
                tabToActive !== undefined &&
                (typeof tabToActive === "number" || tabToActive === 0);
              isActive
                ? handleTabChange(tabToActive)
                : navigate(
                  `/real-estate/property/view/landlord-info/${params?.id}`
                );
            }
          )
        );
      }
    }
  };

  // useEffect(() => {
  //   if (landLordData?.existingLandlord?.landlord_id) {
  //     const landlord = landLordData.existingLandlord;
  //     handleSetLandlordData(
  //       landlord,
  //       setLandLordData,
  //       setAllBankAccounts,
  //       setAllContacts,
  //       setAllFurniture
  //     );
  //   }
  // }, [landLordData?.existingLandlord]);

  // useEffect(() => {
  //   if (landLordData?.existingLandlord?.landlord_id) {
  //     const landlord = landLordData?.existingLandlord;
  //     handleSetLandlordData(
  //       landlord,
  //       setLandLordData,
  //       // setAllBankAccounts,
  //       setAllContacts
  //       // setAllFurniture
  //     );
  //   }
  // }, [landLordData?.existingLandlord?.landlord_id]);

  // useEffect(() => {
  //   if (
  //     !landLordData?.existingLandlord?.landlord_id &&
  //     landLordData?.existingLandlord?.landlordName
  //   ) {
  //     setLandLordData({
  //       id: "",
  //       existingLandlord: landLordData?.existingLandlord?.landlord_name,
  //       landlordName: landLordData?.existingLandlord?.landlord_name,
  //       landlordType: "",
  //       address: "",
  //       houseNo: "",
  //       street: "",
  //       zipCode: "",
  //       city: "",
  //       cityDivision: "",
  //       country: "",
  //       email: "",
  //       phoneNumber: "",
  //       // directDebit: "",
  //       // furnitureProvided: "",
  //       // landlord_furniture: [],
  //       landlord_documents: [
  //         { contact_person: [] },
  //         { change_of_payments: [] },
  //         { change_of_property_management: [] },
  //       ],
  //     });
  //     // setAllBankAccounts([])
  //     setAllContacts([]);
  //     // setAllFurniture([])
  //   }
  // }, [landLordData?.existingLandlord?.landlordName]);

  //contractual
  const [allMaturities, setAllMaturities] = useState([]);
  // const [maturityErrors, setMaturityErrors] = useState([]);

  // const [disableContract, setDisableContract] = useState(false);
  const [ContractOptions, setContractOptions] = useState([]);
  const [contractualData, setContractualData] = useState({
    id: "",
    existingContract: "",
    movingDate: "",
    // estateCompany: "",
    contractualSize: "",
    contractualNo: 0,
    maturityType: "",
    maturityDate: "",
    maturityExtension: "",
    noticePeriod: "",
    depositType: "",
    amount: "",
    furnitureProvided: "",
    // landlord_furniture: [],
    contract_documents: [
      { handover_protocol: [] },
      { key_protocol: [] },
      { floor_plan: [] },
      { photos: [] },
      { energy_bill: [] },
      { energy_contract: [] },
      { energymeter_replacement: [] },
    ],
  });

  const [rentAndCostData, setRentAndCostData] = useState({
    amount: "",
    amountFirstMonth: "",
    rentRiseType: "",
    intervalType: "",
    riseInterval: "",
    startDate: "",
    endDate: "",
    riseAmount: "",
    propertyRent: "Size",
    landlord: "",
  });
  const [isContactExist, setIsContactExist] = useState("");
  const [allExtraCost, setAllExtraCost] = useState([]);
  // const [costErrors, setCostErrors] = useState([]);

  const getContractualInfo = (id) => {
    dispatch(
      getPropertyContractAction(id, dispatch, (res) => {
        // setDisableContract(true);
        handleSetContractData(
          res,
          setContractualData,
          setAllMaturities,
          setAllBankAccounts,
          // setAllFurniture
        );
        handleSetRentData(res, setRentAndCostData, setAllExtraCost);
      })
    );

    dispatch(getFurnitureListAction(params?.id, (res) => {
      handleSetFurniture(res, setAllFurniture)
    }))
  };

  const ContractualChange = ({ name, value, ...rest }) => {
    let ignoreInputs = [];
    const formErrors = formValidation(
      name,
      value,
      contractualData,
      ignoreInputs
    );
    if (name === "document" && value) {
      const { documentType } = rest;
      const documentIndex = contractualData.contract_documents.findIndex(
        (doc) => Object.keys(doc)[0] === documentType
      );
      if (documentIndex !== -1) {
        const updatedBuildingDocument = [...contractualData.contract_documents];
        updatedBuildingDocument[documentIndex] = {
          [documentType]: [
            ...updatedBuildingDocument[documentIndex][documentType],
            { type: documentType, document: value },
          ],
        };

        setContractualData((prev) => ({
          ...prev,
          contract_documents: updatedBuildingDocument,
        }));
      }
    } else {
      if (name === "maturityType" && (value === "None" || !value)) {
        ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod");
        delete formErrors.maturityDate
        delete formErrors.maturityExtension
        delete formErrors.noticePeriod
      }

      if (contractualData?.maturityType === "Automatic Renewal") {
        ignoreInputs.push("noticePeriod");
        ignoreInputs.push("maturityExtension")
        delete formErrors.noticePeriod
        delete formErrors.maturityExtension
      }

      if (contractualData.depositType === "None") {
        ignoreInputs.push("amount");
        delete formErrors.amount
        setContractualData((prev) => ({
          ...prev,
          ["amount"]: null,
          formErrors,
        }));
      }
      if (name === "maturityType" && value === "Automatic Renewal") {
        setContractualData((prev) => ({
          ...prev,
          ["maturityExtension"]: 12,
          formErrors,
        }));
      }

      if (name === "existingContract") {
        if (value?.id) {
          const contract = value
          handleSetContractData(contract, setContractualData, setAllMaturities, setAllBankAccounts)
          dispatch(getFurnitureListAction(params?.id, (res) => {
            handleSetFurniture(res, setAllFurniture)
          }))
        } else if (!value?.id && value?.contract_id) {
          setContractualData({
            id: "",
            existingContract: value?.contract_id,
            movingDate: "",
            // estateCompany: "",
            contractualSize: "",
            contractualNo: 0,
            maturityType: "",
            maturityDate: "",
            maturityExtension: "",
            noticePeriod: "",
            depositType: "",
            amount: "",
            furnitureProvided: "",
            // landlord_furniture: [],
            contract_documents: [
              { handover_protocol: [] },
              { key_protocol: [] },
              { floor_plan: [] },
              { photos: [] },
              { energy_bill: [] },
              { energy_contract: [] },
              { energymeter_replacement: [] },
            ],
          })
          setAllMaturities([])
          setAllBankAccounts([])
          setAllFurniture([])

        }
        setContractualData((prev) => ({ ...prev, [name]: value?.contract_id || value, formErrors }));

      } else {
        setContractualData((prev) => ({ ...prev, [name]: value, formErrors }));
      }
      // setContractualData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    // if (name === "existingContract" && value) {
    //   // contractIdChange.current(value);
    //   setContractualData((prevState) => ({
    //     ...prevState,
    //     id: "",
    //   }));
    // }
  };

  const handleContractSearch = (company, value) => {
    dispatch(
      searchContractAction(company, value, (res) => {
        setContractOptions(res)
        // if (res?.success) {
        //   const info = res?.data?.results;
        //   if (!info.length) {
        //     setIsContactExist(false);
        //   } else {
        //     setIsContactExist(true);
        //   }
        //   setContractOptions(info);
        // }
      })
    );
  };

  const handleContractFileDelete = (index, documentType) => {
    const updatedBuildingDocument = [...contractualData.contract_documents];

    const documentIndex = contractualData.contract_documents.findIndex(
      (doc) => Object.keys(doc)[0] === documentType
    );

    const documents =
      contractualData?.contract_documents[documentIndex][documentType];
    documents.splice(index, 1);
    updatedBuildingDocument[documentIndex] = {
      [documentType]: [...updatedBuildingDocument[documentIndex][documentType]],
      documents,
    };

    setContractualData((prev) => ({
      ...prev,
      ["contract_documents"]: updatedBuildingDocument,
    }));
  };

  const contractIdChange = useRef(
    debounce((value) => {
      handleContractSearch(userProfileRef.current?.company, value);
    }, 1000)
  );

  const ContractualSubmit = () => {
    const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
    const furniture = checkFurnitureErrors(allFurniture, setAllFurniture)
    const maturities = checkOptionalMaturtiesErrors(allMaturities, setAllMaturities);
    let ignoreInputs = [];
    if (
      contractualData?.maturityType === "None" ||
      (!contractualData?.maturityType && contractualData?.formErrors)
    ) {
      ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod");
      delete contractualData?.formErrors?.maturityDate;
      delete contractualData?.formErrors?.maturityExtension;
      delete contractualData?.formErrors?.noticePeriod;
    }
    if (contractualData?.maturityType === "Optional Renewal") {
      ignoreInputs.push("noticePeriod", "maturityExtension");
      delete contractualData?.formErrors?.noticePeriod;
    }

    if (contractualData?.depositType === "None") {
      ignoreInputs.push("amount");
    }

    if (showFormErrors(contractualData, setContractualData, ignoreInputs) && !maturities.hasErrors && !bankAccounts?.hasErrors && !furniture?.hasErrors) {
      let data = {
        ...contractualData,
        landlordId: landLordData?.id,
        property: params?.id,
        optional_maturities: maturities?.optionalMaturities,
        bank_accounts: bankAccounts?.bankAccounts,
        landlord_furniture: furniture?.furniture,
      };

      if (contractualData?.id) {
        dispatch(
          updateContractAction(
            profile?.company,
            contractualData?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetContractData(
                res,
                setContractualData,
                setAllMaturities,
                setAllBankAccounts,
                // setAllFurniture
              );
              dispatch(getFurnitureListAction(params?.id, (res) => {
                handleSetFurniture(res, setAllFurniture)
              }))
              dispatch(setPropertyContractSlice(res));

              const isActive =
                tabToActive !== null &&
                tabToActive !== undefined &&
                (typeof tabToActive === "number" || tabToActive === 0);
              isActive
                ? handleTabChange(tabToActive)
                : navigate(
                  `/real-estate/property/view/contractual-info/${params?.id}`
                );
            }
          )
        );
      } else {
        dispatch(
          addContractAction(
            profile?.company,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetContractData(
                res,
                setContractualData,
                setAllMaturities,
                setAllBankAccounts,
                // setAllFurniture
              );
              dispatch(getFurnitureListAction(params?.id, (res) => {
                handleSetFurniture(res, setAllFurniture)
              }))
              dispatch(setPropertyContractSlice(res));
              const isActive =
                tabToActive !== null &&
                tabToActive !== undefined &&
                (typeof tabToActive === "number" || tabToActive === 0);
              isActive
                ? handleTabChange(tabToActive)
                : navigate(
                  `/real-estate/property/view/contractual-info/${params?.id}`
                );
            }
          )
        );
      }
      setIsContactExist(true)
    }
  };

  // useEffect(() => {
  //   if (contractualData?.existingContract?.contract_id) {
  //     const contract = contractualData.existingContract;
  //     handleSetContractData(
  //       contract,
  //       setContractualData,
  //       setAllMaturities,
  //       setAllBankAccounts,
  //       // setAllFurniture
  //     );
  //     dispatch(getFurnitureListAction(params?.id, (res) => {
  //       handleSetFurniture(res, setAllFurniture)
  //     }))
  //   }
  // }, [contractualData?.existingContract]);

  //rent and cost
  const RentAndCostChange = ({ name, value }) => {
    let isFirstDay = isFirstDayOfMonth(contractualData?.movingDate);
    let ignoreInputs = [];
    if (name === "rentRiseType" && (value === "None" || !value)) {
      ignoreInputs.push(
        "intervalType",
        "riseInterval",
        "startDate",
        "riseAmount",
        "endDate"
      );
    }

    if (isFirstDay) {
      ignoreInputs.push("amountFirstMonth");
    } else {
      ignoreInputs = ignoreInputs.filter((item) => item != "amountFirstMonth");
    }

    const formErrors = formValidation(
      name,
      value,
      rentAndCostData,
      ignoreInputs
    );
    setRentAndCostData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const RentAndCostSubmit = () => {
    let isFirstDay = isFirstDayOfMonth(contractualData?.movingDate);
    const extraCosts = checkExtraCostErrors(allExtraCost, setAllExtraCost);
    let ignoreInputs = [];

    if (
      rentAndCostData?.rentRiseType === "None" ||
      (!rentAndCostData?.rentRiseType && rentAndCostData?.formErrors)
    ) {
      ignoreInputs.push(
        "intervalType",
        "riseInterval",
        "startDate",
        "riseAmount",
        "endDate"
      );
      delete rentAndCostData?.formErrors?.intervalType;
      delete rentAndCostData?.formErrors?.riseInterval;
      delete rentAndCostData?.formErrors?.startDate;
      delete rentAndCostData?.formErrors?.riseAmount;
      delete rentAndCostData?.formErrors?.endDate;
    }

    if (isFirstDay) {
      ignoreInputs.push("amountFirstMonth");
      delete rentAndCostData?.formErrors?.amountFirstMonth;
    } else {
      ignoreInputs = ignoreInputs.filter((item) => item != "amountFirstMonth");
    }

    if (
      showFormErrors(rentAndCostData, setRentAndCostData, ignoreInputs) &&
      !extraCosts?.hasErrors
    ) {
      let data = {
        ...rentAndCostData,
        extra_costs: extraCosts?.extraCosts,
      };

      dispatch(
        addRoomAndCostAction(
          profile.company,
          contractualData?.id,
          data,
          setLoading,
          dispatch,
          (res) => {
            handleSetRentData(res, setRentAndCostData, setAllExtraCost);
            const isActive =
              tabToActive !== null &&
              tabToActive !== undefined &&
              (typeof tabToActive === "number" || tabToActive === 0);
            isActive
              ? handleTabChange(tabToActive)
              : navigate(
                `/real-estate/property/view/rent-and-cost/${params?.id}`
              );
          }
        )
      );
    }
  };

  useMemo(() => {
    if (activeIndex === 1) {
      buildingUnitDataRedux &&
        handleSetBuildingUnitData(
          buildingUnitDataRedux,
          setBuildingUnitdata,
          setAllRooms,
          setAllEnergyMeters
        );
    } else if (activeIndex === 2) {
      landlordDataRedux
        ? handleSetLandlordData(
          landlordDataRedux,
          setLandLordData,
          // setAllBankAccounts,
          // setAllContacts
          // setAllFurniture
        )
        : getLandlordInfo(params?.id);
    } else if (activeIndex === 3 || activeIndex === 4) {
      !landlordDataRedux && getLandlordInfo(params?.id);
      // if (ContractDataRedux) {
      //   handleSetContractData(
      //     ContractDataRedux,
      //     setContractualData,
      //     setAllMaturities,
      //     setAllBankAccounts,
      //     // setAllFurniture
      //   )

      //   dispatch(getFurnitureListAction(params?.id, (res) => {
      //     handleSetFurniture(res, setAllFurniture)
      //   }))
      // } else {
      //   getContractualInfo(params?.id);
      // }
      getContractualInfo(params?.id);
      dispatch(getFurnitureListAction(params?.id, (res) => {
        handleSetFurniture(res, setAllFurniture)
      }))
      // ContractDataRedux &&
      //   handleSetRentData(
      //     ContractDataRedux,
      //     setRentAndCostData,
      //     setAllExtraCost
      //   );
    }
  }, [
    location?.pathname,
    // activeIndex,
    buildingUnitDataRedux,
    landlordDataRedux,
    // ContractDataRedux,
  ]);

  const onSubmit = (route) => {
    setIsOpen(false);
    if (route === "property") {
      propertySubmit();
    } else if (route === "building-structure") {
      // BuildingStuctureSubmit();
      if (showFormErrors(buildingStuctureData, setBuildingStuctureData, [
        "document",
        "longitude",
        "latitude",
        "address",
        "street",
        "houseNo",
        "zipCode",
        "country",
        "city",
        "cityDivision",
      ])) {
        setIsWarning(true)
      }
    } else if (route === "building-unit") {
      BuildingUnitSubmit();
    } else if (route === "landlord-info") {
      LandlordSubmit();
    } else if (route === "contractual-info") {
      ContractualSubmit();
    } else if (route === "rent-and-cost") {
      RentAndCostSubmit();
    }
  };

  const handleCancel = () => {
    if (route === "property" || route === "building-structure") {
      propertyDataRedux
        ? handleSetPropertyData(propertyDataRedux, setPropertyData)
        : getPropertyInfo(profile?.company);
      buildingStructureDataRedux &&
        handleSetBuildingSturctureData(
          "",
          buildingStructureDataRedux || buildingStuctureData,
          setBuildingStuctureData,
          setStructureDocuments
        );
    } else if (route === "building-unit") {
      // getBuildingUnitInfo(params?.id);
      handleSetBuildingUnitData(
        buildingUnitDataRedux || buildingUnitData,
        setBuildingUnitdata,
        setAllRooms,
        setAllEnergyMeters
      );
    } else if (route === "landlord-info") {
      getLandlordInfo(params?.id);
      // handleSetLandlordData(
      //   landlordDataRedux || landLordData,
      //   setLandLordData,
      //   // setAllBankAccounts,
      //   setAllContacts
      //   // setAllFurniture
      // );
    } else if (route === "contractual-info" || route === "rent-and-cost") {
      handleSetContractData(
        ContractDataRedux || contractualData,
        setContractualData,
        setAllMaturities,
        setAllBankAccounts,
        // setAllFurniture
      );
      handleSetRentData(
        ContractDataRedux || rentAndCostData,
        setRentAndCostData,
        setAllExtraCost
      );
      // getLandlordInfo(params?.id);
      // getContractualInfo(params?.id);
    }
    const isActive =
      tabToActive !== null &&
      tabToActive !== undefined &&
      (typeof tabToActive === "number" || tabToActive === 0);
    isActive
      ? handleTabChange(tabToActive)
      : navigate(`/real-estate/property/view/${route}/${params?.id}`);
  };

  return {
    action,
    route,
    activeIndex,
    handleTabChange,
    onSubmit,
    propertyIdOptions,
    setTabToActive,

    loading,
    handleCancel,
    isOpen,
    setIsOpen,
    modalContent,

    //Property
    PropertyData,
    // propertyId,
    propertyChange,
    propertySubmit,
    setSelectedAddress,
    propertyIdChange,

    //Building Structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    // fileKey,
    // sturcureDocuments,
    // handleStructureFileChange,
    handleStructureFileDelete,
    isWarning,
    setIsWarning,
    BuildingStuctureSubmit,

    //Building Unit
    buildingUnitData,
    BuildingUnitChange,
    allRooms,
    setAllRooms,
    handleUnitFileDelete,
    // roomErrors,
    // setRoomErrors,

    allEnergyMeters,
    setAllEnergyMeters,
    // meterErrors,
    // setMeterErrors,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    // disableLandloard,
    setLandlordAddress,
    landlordIdChange,
    handleLandlordFileDelete,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,
    // bankErrors,
    // setBankErrors,

    //contacts
    allContacts,
    setAllContacts,

    // contactErrors,
    // setContactErrors,
    // disableContract,

    //furniture
    allFurniture,
    setAllFurniture,
    // furnitureErrors,
    // setFurnitureErrors,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    // maturityErrors,
    // setMaturityErrors,
    contractIdChange,
    isContactExist,
    handleContractFileDelete,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,
    // RentAndCostSubmit,

    //extra cost
    allExtraCost,
    setAllExtraCost,
    propertyId: params?.id,
    // costErrors,
    // setCostErrors,
  };
};

export default ViewEditPropertyContainer;
