import api from "services/api";
import endPoints from "services/endPoints";
import { showToastAction } from "store/slices/commonSlice";
import { capitalizeCamelCase } from "utils/commonFunctions";

const filterExternalRentAction = (regulation, dispatch, setRegulationOptions, onError) => async () => {
    const res = await api(
        "get",
        endPoints?.RENTSETUP + `/${regulation}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        const options = info?.map((item) => {
            return {
                ...item,
                name: `${item.regulation_name} ${capitalizeCamelCase(item.federal_state)}`,
                value: item.regulation_name,
            }
        })
        setRegulationOptions(options)
        if (!options?.length) {
            onError && onError("Regulation is not found!")
        }

    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const getLinkedServicesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.TENANT_SERVICE_SETUP + `/${company}/${endPoints?.LINKED_SERVICES}`,
        ///?limit=${rows}&page=${page}&search=${search || ""}&search=${search || ""}&name=${name || ""}&email=${email || ""}
    );

    if (res?.success) {
        onRes({ options: res?.data?.results, count: res?.data?.count || res?.data?.results?.length })
    }
};

const addTierAction = (tier, dispatch, setLoading, onRes) => async () => {
    setLoading(true)

    const res = await api(
        "post",
        endPoints?.TIER_EXTERNAL_RENTSETUP,
        tier,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes && onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    setLoading(false)

}

const updateTierAction = (tierId, tier, dispatch, setLoading, onRes) => async () => {
    setLoading(true)
    delete tier.data
    const res = await api(
        "put",
        endPoints?.TIER_EXTERNAL_RENTSETUP + `${tierId}/`,
        tier,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes && onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }

    setLoading(false)

}

const createExternalRentSetupAction = (company, regulation, tiers, dispatch, setLoading, onRes) => async () => {
    setLoading(true)
    const payload = {
        regulation_name: regulation?.regulation_name,
        federal_state: regulation?.federal_state,
        tier_rent_setups: [...tiers]
    }
    const res = await api(
        "post",
        endPoints?.EXTERNAL_RENTSETUP + `${company}/`,
        payload,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes && onRes(info)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
}

const getExternalRentSetupAction = (company, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EXTERNAL_RENTSETUP + `${company}${endPoints?.EXTERNAL_RENTSETUP_BY_COMPANY}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes && onRes(info)
    } else {
        onRes && onRes(null)
    }
}

export {
    filterExternalRentAction,
    getLinkedServicesListAction,
    addTierAction,
    updateTierAction,
    createExternalRentSetupAction,
    getExternalRentSetupAction,
}