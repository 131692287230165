import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { InitialFamilyInputs } from "shared/initialInputs";
import { checkErrors } from "shared/Components/commonFunctions";
import { addTenantAction, addTenantFamilyAction } from "store/actions/TenantsActions";
import { setTenantSlice } from "store/slices/tenantSlice";
import { useNavigate } from "react-router-dom";

const TenantContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        id: "",
        tenantId: "",
        firstName: "",
        lastName: "",
        tenantType: "",
        dateOfBirth: "",
        email: "",
        phoneNumber: "",
        addressBeforeMovingIn: "",
        socialOffice: "",
        employee: "",
        service: "",
        contractDate: ""
    });
    const [allFamilies, setAllFamilies] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [loading, setLoading] = useState(false);
    const userProfileRef = useRef()
    const [disableTabs, setDisableTabs] = useState([]) //1, 2
  

    useEffect(() => {
        userProfileRef.current = profile
    }, [profile])

    useEffect(() => {
        if (data?.id) {
            setAllFamilies([InitialFamilyInputs(data?.id)])
        }
    }, [data?.id])

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    const handleTabChange = (index) => {
        setActiveIndex(index)
        const updatedTabs = disableTabs.filter(tab => tab !== index)
        setDisableTabs(updatedTabs)
    }

    const tenantSubmit = () => {
        if (showFormErrors(data, setData, ["id"])) {
            dispatch(
                addTenantAction(profile?.company, data, setLoading, dispatch, (res) => {
                    setData((prev) => ({ ...prev, id: res?.id }))
                    handleTabChange(activeIndex + 1)
                })
            )
        }
    }

    const familySubmit = () => {
        const famlies = checkErrors(allFamilies, setAllFamilies, ["first_name", "last_name", "date_of_birth"])
        if (!famlies.hasErrors && famlies?.values?.length) {
            dispatch(addTenantFamilyAction(famlies?.values, setLoading, dispatch, (res) => {
                handleTabChange(activeIndex + 1)
            }))
        } else {
            handleTabChange(activeIndex + 1)
        }
    }

    const onSubmit = () => {
        if (activeIndex === 0) {
            tenantSubmit()
        } else if (activeIndex === 1) {
            familySubmit()
        } else if (activeIndex === 2) {
            dispatch(setTenantSlice(data))
            navigate("/tenants/list")
        }
    };

    return {
        data,
        setData,
        handleChange,
        disableTabs,
        handleTabChange,
        activeIndex,
        onSubmit,
        allFamilies,
        setAllFamilies,
        loading,
        tenantId: data?.id || null
    }
}

export default TenantContainer